.template--probate-campaign {


    /**
     * Hero
     */
    .section-hero {}

    .section-hero__inner {
        @include wrap();
        display: flex;
        align-items: center;
        padding-top: 50px;
        padding-bottom: 70px;
        border-bottom: 1px solid $color--panel-border;
    }

    .section-hero__content {
        flex-basis: 50%;
    }

    .section-hero__heading {
        font-size: 45px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        margin-bottom: 25px;
    }

    .section-hero__copy {
        font-size: 20px;
        font-weight: $font-weight--medium;
        line-height: 1.5;
        color: $color--text;
    }

    .section-hero__cta {
        margin-top: 30px;
    }

    .section-hero__reviews {
        margin-top: 40px;
    }

    .section-hero__illustration {
        display: flex;
        justify-content: flex-end;
        flex-basis: 50%;

        img {
            max-width: 500px;
        }

    }


    @media screen and (max-width: 768px) {

        .section-hero__inner {
            flex-direction: column-reverse;
            align-items: flex-start;
        }

        .section-hero__illustration {
            margin-bottom: 30px;

            img {
                max-width: 290px;
            }
        }

        .section-hero__heading {
            font-size: 38px;
            margin-bottom: 20px;
        }

        .section-hero__copy {
            font-size: 19px;
        }

    }




    /**
     * Sales points
     */
    .section-sales-points {}

    .section-sales-points__inner {
        @include wrap();
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .section-sales-points__heading {
        font-size: 40px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        text-align: center;
        max-width: 768px;
        margin: auto;
        margin-bottom: 45px;
    }

    .section-sales-points__points {
        display: flex;
        justify-content: space-between;
        max-width: 1080px;
        margin: auto;
    }

    .section-sales-points__point {
        max-width: 300px;
        padding-top: 220px;
        position: relative;

        &:not(:last-child) {
            margin-right: 30px;
        }

    }

    .section-sales-points__point-illustration {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 200px;
    }

    .section-sales-points__point-heading {
        font-size: 20px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        text-align: center;
        margin-bottom: 15px;
    }

    .section-sales-points__point-copy {
        font-size: 19px;
        font-weight: $font-weight--regular;
        color: $color--text;
        text-align: center;
    }


    @media screen and (max-width: 768px) {

        .section-sales-points__heading {
            font-size: 32px;
            margin-bottom: 35px;
        }

        .section-sales-points__points {
            flex-direction: column;
            align-items: center;
        }

        .section-sales-points__point {

            &:not(:last-child) {
                margin-right: 0px;
            }

            margin-bottom: 20px;
        }

    }





    /**
     * Services
     */
    .section-services {
        background-color: $color--brand-primary-light;
    }

    .section-services__inner {
        @include wrap();
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .section-services__heading {
        font-size: 38px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        text-align: center;
        max-width: 768px;
        margin: auto;
        margin-bottom: 35px;
    }

    .section-services__cta {
        display: block;
        margin: auto;
        max-width: 250px;
        margin-top: 30px;
    }

    .section-services__grid {
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
    }

    .section-services__service {
        flex-basis: calc(50% - 15px);
        margin-bottom: 30px;
        background-color: $color--panel;
        border-radius: 5px;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 30px;
        padding-right: 30px;
        box-shadow: 0px 10px 33px -16px rgba(#000, 0.3);

        &:nth-child(odd) {
            margin-right: 30px;
        }

    }

    .section-services__service-heading {
        font-size: 32px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        margin-bottom: 25px;
    }

    .section-services__service-subheading {
        font-size: 25px;
        font-weight: $font-weight--medium;
        color: $color--heading;
        padding-bottom: 30px;
        margin-bottom: 25px;
        border-bottom: 1px solid $color--panel-border;
    }

    .section-services__service-pricing {
        position: relative;
        margin-bottom: 40px;
    }

    .section-services__service-pricing-heading {
        font-size: 22px;
        font-weight: $font-weight--regular;
        color: $color--heading;
        margin-bottom: 5px;
        width: calc(100% - 185px);
    }

    .section-services__service-pricing-price {
        font-size: 45px;
        font-weight: $font-weight--bold;
        color: $color--heading;
    }

    .section-services__service-illustration {
        position: absolute;
        right: 0;
        top: 0;

        max-width: 175px;
        max-height: 100px;
    }

    .section-services__service-pricing-copy {
        font-size: 17px;
        font-weight: $font-weight--regular;
        color: $color--text;
        line-height: 1.5;
        padding-bottom: 30px;
        margin-bottom: 25px;
        border-bottom: 1px solid $color--panel-border;
    }

    .section-services__service-sales-heading {
        font-size: 17px;
        font-weight: $font-weight--regular;
        color: $color--text;
        line-height: 1.5;
    }

    .section-services__service-sales-points {
        margin-top: 20px;
    }

    .section-services__service-sales-point {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

    }

    .section-services__service-sales-point-icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $color--brand-primary-light;
        margin-right: 20px;
        margin-top: 5px;

        img {
            width: 20px;
        }
    }

    .section-services__service-sales-point-text {
        flex: 1;
    }

    .section-services__service-sales-point-heading {
        font-size: 19px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        margin-bottom: 8px;
    }

    .section-services__service-sales-point-copy {
        font-size: 17px;
        font-weight: $font-weight--regular;
        color: $color--text;
        line-height: 1.5;
    }


    @media screen and (max-width: 850px) {

        .section-services__service {
            flex-basis: 100%;

            &:nth-child(odd) {
                margin-right: 0px;
            }

        }

    }

    @media screen and (max-width: 768px) {

        .section-services__heading {
            font-size: 32px;
            margin-bottom: 25px;
        }

        .section-services__service-heading {
            font-size: 27px;
            margin-bottom: 15px;
        }

        .section-services__service-pricing-price {
            font-size: 35px;
        }

    }





    /**
     * Steps
     */
    .section-steps {}

    .section-steps__inner {
        @include wrap();
        padding-top: 90px;
        padding-bottom: 70px;
        max-width: 1080px;
    }

    .section-steps__heading {
        font-size: 40px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        text-align: center;
        max-width: 650px;
        margin: auto;
        margin-bottom: 25px;
    }

    .section-steps__subheading {
        font-size: 26px;
        font-weight: $font-weight--regular;
        color: $color--text;
        text-align: center;
        max-width: 650px;
        margin: auto;
        margin-bottom: 75px;
    }

    .section-steps__rows {}

    .section-steps__row {
        display: flex;
        align-items: center;
        position: relative;


        &:nth-child(odd) {

            .section-steps__row-illustration {
                order: 1;
            }

            .section-steps__row-number {
                order: 2;
                margin-left: 50px;
                margin-right: 60px;
            }

            .section-steps__row-content {
                order: 3;
            }

            &:not(:last-child) {

                &:after {
                    content: '';
                    position: absolute;
                    left: 100px;
                    bottom: 20px;
                    width: 250px;
                    height: 150px;
                    background-image: url(/wp-content/themes/Aticus2021/assets/dist/images/probate-campaign/step-odd-line.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                }

            }

        }


        &:nth-child(even) {

            .section-steps__row-illustration {
                order: 3;
                margin-left: 30px;
            }

            .section-steps__row-number {
                order: 1;
                margin-left: 40px;
                margin-right: 60px;
            }

            .section-steps__row-content {
                order: 2;
            }

            &:not(:last-child) {

                &:after {
                    content: '';
                    position: absolute;
                    left: 90px;
                    bottom: -10px;
                    width: 270px;
                    height: 110px;
                    background-image: url(/wp-content/themes/Aticus2021/assets/dist/images/probate-campaign/step-even-line.png);
                    background-size: cover;
                    background-repeat: no-repeat;
                }

            }

        }


        &:not(:last-child) {
            padding-bottom: 150px;
        }

    }

    .section-steps__row-illustration {
        max-width: 250px;
    }

    .section-steps__row-number {
        flex-basis: 100px;
        width: 100px;
        height: 100px;
        font-size: 28px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $color--aticus-green-light;
    }

    .section-steps__row-content {
        flex: 1;
    }

    .section-steps__row-heading {
        font-size: 25px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        margin-bottom: 20px;
    }

    .section-steps__row-copy {
        font-size: 20px;
        font-weight: $font-weight--regular;
        color: $color--text;
        line-height: 1.5;
    }


    @media screen and (max-width: 768px) {

        .section-steps__inner {
            padding-top: 60px;
            padding-bottom: 40px;
        }

        .section-steps__heading {
            font-size: 32px;
            margin-bottom: 25px;
        }

        .section-steps__subheading {
            font-size: 22px;
            margin-bottom: 45px;
        }

        .section-steps__row {
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;

            &:nth-child(odd) {

                .section-steps__row-illustration {
                    order: 2;
                }

                .section-steps__row-number {
                    order: 1;
                    margin-left: 0px;
                    margin-right: 20px;
                }

                .section-steps__row-content {
                    order: 3;
                    flex-basis: 100%;
                }

                &:not(:last-child) {

                    &:after {
                        display: none;
                    }

                }

            }


            &:nth-child(even) {

                .section-steps__row-illustration {
                    order: 2;
                    margin-left: 0px;
                }

                .section-steps__row-number {
                    order: 1;
                    margin-left: 0px;
                    margin-right: 20px;
                }

                .section-steps__row-content {
                    order: 2;
                    flex-basis: 100%;
                }

                &:not(:last-child) {

                    &:after {
                        display: none;
                    }

                }

            }

            &:not(:last-child) {
                padding-bottom: 60px;
            }

        }

        .section-steps__row-illustration {
            max-width: 200px;
        }

        .section-steps__row-number {
            flex-basis: 50px;
            width: 50px;
            height: 50px;
            font-size: 22px;
            margin-bottom: 10px;
        }

        .section-steps__row-content {
            margin-top: 20px;
        }

        .section-steps__row-heading {
            font-size: 22px;
            margin-bottom: 18px;
        }

        .section-steps__row-copy {
            font-size: 18px;
        }

    }




    /**
     * CTA section
     */
    .section-cta {
        background-color: $color--panel-contrast;
    }

    .section-cta__inner {
        @include wrap();
        // max-width: 1080px;
        padding-top: 90px;
        padding-bottom: 70px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .section-cta__content {
        flex-basis: 50%;
    }

    .section-cta__heading {
        font-size: 40px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        max-width: 650px;
        margin-bottom: 25px;
    }

    .section-cta__subheading {
        font-size: 26px;
        font-weight: $font-weight--regular;
        color: $color--text;
        max-width: 650px;
        margin-bottom: 25px;
    }

    .section-cta__points {
        list-style-type: none;
        padding: 0px;
        margin-bottom: 30px;
    }

    .section-cta__point {
        display: flex;
        align-items: center;
        max-width: 650px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        img {
            width: 25px;
            margin-right: 15px;
        }

        .bullet {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: $color--aticus-green;
            margin-right: 15px;
        }

        span {
            font-size: 24px;
            font-weight: $font-weight--regular;
            color: $color--text;
        }

    }

    .section-cta__btn {}

    .section-cta__team-member {
        display: flex;
        justify-content: flex-end;

        .team-member-card {
            width: 350px;
            background-color: $color--panel;
        }

    }


    @media screen and (max-width: 850px) {

        .section-cta__team-member .team-member-card {
            width: 300px;
        }

    }


    @media screen and (max-width: 768px) {

        .section-cta {
            background-color: $color--panel-contrast;
        }

        .section-cta__inner {
            padding-top: 50px;
            padding-bottom: 60px;
        }

        .section-cta__content {
            flex-basis: 100%;
        }

        .section-cta__heading {
            font-size: 34px;
            margin-bottom: 25px;
        }

        .section-cta__subheading {
            font-size: 22px;
            margin-bottom: 20px;
        }

        .section-cta__points {
            margin-bottom: 20px;
        }

        .section-cta__point {

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            img {
                width: 22px;
                margin-right: 12px;
            }

            span {
                font-size: 21px;
            }

        }


        .section-cta__team-member {
            display: none;
        }

    }




}
