.live-chat-cta {
    background-color: $color--panel-dark;
    position: fixed;
    z-index: 1;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 37px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 21px;
    border-top-left-radius: 16px;
    cursor: pointer;

    div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-bottom: 10px;
        background-color: $color--brand-primary;
        user-select: none;
        pointer-events: none;
    }

    span {
        display: block;
        text-align: center;
        color: $color--text-light;
        font-weight: $font-weight--medium;
        font-size: 18px;
        transform: rotate(-90deg) translateX(-51px);
        width: 110px;
        user-select: none;
        pointer-events: none;
    }

    &:hover {
        background-color: lighten($color--panel-dark, 5%);
    }

}

.BeaconFabButtonFrame {
    display: none;
}


.BeaconContainer {
    right: 60px !important;
    bottom: 40px !important;
    max-width: 500px;
    width: 100% !important;
}

@media screen and (max-width: 450px) {

    .BeaconContainer {
        max-height: 90vh !important;
        top: 40px !important;
        width: 90vw !important;
    }

}

.live-chat-bg {
    @include absoluteFit();
    position: fixed;
    background-color: rgba($color--panel-dark, 0.4);
    transition: all 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 101;
    cursor: pointer;
}

body.live-chat-open {
    .live-chat-bg {
        opacity: 1;
        visibility: visible;
    }
    .live-chat-cta {
        z-index: 102;
    }
}


@media screen and (max-width: 600px) {
    .live-chat-bg {
        display: none !important;
    }
}
