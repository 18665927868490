.team-block {
}

.team-block__inner {
    @include wrap();
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    max-width: 992px;

    .team-block-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    #team_search {
        padding: 20px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
    }
}

.team-block__member {
    flex-basis: calc(33.33333333% - 20px);
    margin-bottom: 30px;

    &:not(:nth-child(3n)) {
        margin-right: 30px;
    }

    &.hidden {
        display: none;
        visibility: hidden;
    }
}

.team-block__heading {
    flex-basis: 100%;
    font-size: 23px;
    font-weight: $font-weight--bold;
    color: $color--heading;

    padding-bottom: 15px;
    margin-top: 30px;
    margin-bottom: 60px;
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 2px solid $color--aticus-green;
}

.team-block__partner {
    flex-basis: calc(50% - 15px);
    margin-bottom: 30px;

    .team-member-card__photo {
        height: 300px;
    }

    &:not(:nth-of-type(2n)) {
        margin-right: 30px;
    }
}

@media screen and (max-width: 992px) {
    .team-block__member {
        flex-basis: calc(50% - 15px);

        &:not(:nth-child(3n)) {
            margin-right: 0px;
        }

        &:not(:nth-child(2n)) {
            margin-right: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .team-block__partner {
        flex-basis: 100%;

        &:not(:nth-child(2n)) {
            margin-right: 0px;
        }

        .team-member-card__photo {
            height: 60vw;
        }
    }

    .team-block__member {
        flex-basis: 100%;

        &:not(:nth-child(2n)) {
            margin-right: 0px;
        }

        .team-member-card__photo {
            height: 60vw;
        }
    }
}
