.google-reviews-modal {
    @include absoluteFit();
    position: fixed;
    z-index: 101;
    overflow: hidden;

    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;

    &.modal-open {
        opacity: 1;
        visibility: visible;

        .google-reviews-modal__inner {
            transform: translateY(-50%) scale(1);
        }

    }

}

.google-reviews-modal__bg {
    @include absoluteFit();
    background-color: rgba($color--panel-dark, 0.8);
    cursor: pointer;
}

.google-reviews-modal__inner {
    @include absoluteFit();
    @include wrap();
    position: relative;
    top: 50%;
    width: 90%;
    max-width: 768px;
    height: 85vh;
    max-height: 580px;
    padding-top: 50px;
    overflow: auto;
    background-color: rgba($color--panel, 0.95);
    backdrop-filter: blur(15px);
    border-radius: 42px;
    border-top-left-radius: 0px;

    transform: translateY(-48%) scale(0.95);
    transition: transform 0.25s ease-in-out;

    .review-text {
        justify-content: center;
        margin-bottom: 40px;
    }

}


.google-reviews-modal__reviews {
    padding-bottom: 40px;
}

.google-reviews-modal__review {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.google-reviews-modal__error {
    font-size: 17px;
    font-weight: $font-weight--regular;
    text-align: center;
}


.google-reviews-modal__button {
    margin-bottom: 50px;
}
