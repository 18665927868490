.review-text-block {
    border-top: 1px solid $color--panel-border;
    border-bottom: 1px solid $color--panel-border;
    margin-top: 50px;
    margin-bottom: 50px;
}

.review-text-block__inner {
    @include wrap();
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
}


@media screen and (max-width: 768px) {

    .review-text-block .review-text {
        transform: scale(0.8);
    }

}


@media screen and (max-width: 600px) {

    .review-text-block__inner {
        padding-top: 30px;
    }

    .review-text-block .review-text {
        transform: scale(1);
        justify-content: center;

        .review-text__text {

            &:first-of-type {
                margin-right: 0px;
                flex-basis: 100%;
                text-align: center;
                margin-bottom: 10px;
                font-size: 17px;
            }

        }

        .review-text__stars {
            flex-basis: 100%;
            margin-right: 0px;
            justify-content: center;
        }

    }

}
