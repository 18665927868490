.sitemap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.sitemap__group {
    flex-basis: 48%;
    margin-bottom: 15vh;
    max-width: 400px;
}

.sitemap__heading {}

.sitemap__list {
    list-style-type: disclosure-closed !important;
    padding-left: 17px;

    li {
        margin-bottom: 30px;
        margin-top: 50px;
        padding-left: 20px;
    }

    a {
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            max-width: 120px;
            height: 4px;
            border-radius: 6px;
            background-color: $color--brand-primary;
            bottom: -10px;
            left: 0;
            right: 0;
            transition: bottom 0.2s;
        }

        &:hover {
            &:after {
                bottom: -12px;
            }
        }

    }

}


@media screen and (max-width: 768px) {

    .sitemap {
        display: block;
    }

}
