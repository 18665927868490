.landing-hero-form-modal {
    @include absoluteFit();
    position: fixed;
    z-index: 101;

    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;

    &.modal-open {
        opacity: 1;
        visibility: visible;

        .landing-hero-form-modal__inner {
            transform: translateY(-50%) scale(1);
        }

    }

}

.landing-hero-form-modal__bg {
    @include absoluteFit();
    background-color: rgba($color--panel-dark, 0.8);
    cursor: pointer;
}

.landing-hero-form-modal__inner {
    @include absoluteFit();
    @include wrap();
    position: relative;
    top: 50%;
    width: 90%;
    max-width: 768px;
    max-height: 90vh;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    overflow: auto;
    background-color: $color--panel;
    box-shadow: 0px 10px 22px -11px rgba(0, 0, 0, 0.4);
    border-radius: 12px;

    transform: translateY(-48%) scale(0.95);
    transition: transform 0.25s ease-in-out;
}

.landing-hero-form-modal__heading {
    font-size: 25px;
    font-weight: $font-weight--regular;
    color: $color--heading;
    margin-bottom: 40px;
    // text-align: center;

    strong {
        font-weight: $font-weight--bold;
    }
}

.landing-hero-form-modal__success {
    display: flex;
    align-items: flex-start;
}

.landing-hero-form-modal__success-text {
    margin-right: 30px;
    flex: 1;
}

.landing-hero-form-modal__success-heading {
    font-size: 27px;
    font-weight: $font-weight--bold;
    color: $color--heading;
    margin-bottom: 20px;
}

.landing-hero-form-modal__success-copy {
    font-size: 18px;
    font-weight: $font-weight--medium;
    line-height: 1.43;
    color: $color--text;
}

.landing-hero-form-modal__success-icon {
    width: 70px;
}


.landing-hero-form-modal__form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
    border-top: 1px solid $color--panel-border;
    margin-top: 30px;

    .form-group {
        flex-basis: 100%;

        &:not(:last-child) {
            margin-bottom: 25px;
        }

        &:not(.full-width) {
            flex-basis: calc(50% - 15px);

            &:first-child {
                margin-right: 30px;
            }
        }

        textarea {
            min-height: 120px;
            width: 100%;
        }

    }

    &.hidden {
        display: none;
    }

}


.landing-hero-form-modal__form-submit {
}


@media screen and (max-width: 650px) {

    .landing-hero-form-modal__inner {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .landing-hero-form-modal__success {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .landing-hero-form-modal__success-icon {
        width: 55px;
    }

    .landing-hero-form-modal__success-text {
        margin-right: 20px;
    }

    .landing-hero-form-modal__success-heading {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .landing-hero-form-modal__success-copy {
        font-size: 15px;
    }

    .landing-hero-form-modal__form .form-group:not(.full-width) {
        flex-basis: 100%;
    }

}
