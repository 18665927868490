.expert-opinion {
    width: 100%;
    background-color: #f6f6f6;
    border-radius: 12px;
}

.expert-opinion__inner {
    padding: 20px;
}

.expert-opinion__heading {
    font-size: 25px;
    font-weight: 700;
    color: #333A45;
    margin-top: 0px;
    margin-bottom: 10px;
}

.expert-opinion__content {
    font-size: 18px;
    font-weight: 500;
    color: #333A45;
    margin: 0px;
}

.expert-opinion__content p:last-child {
    margin-bottom: 0px !important;
}

.expert-opinion__author {
    font-weight: 600;
    font-size: 16px;
    display: block;
    margin-top: -10px;
}
