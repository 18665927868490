.services-request-callback-form__success {
    display: flex;
    align-items: flex-start;
    display: none;
}

.services-request-callback-form__success-text {
    margin-right: 30px;
    flex: 1;
}

.services-request-callback-form__success-heading {
    font-size: 27px;
    font-weight: $font-weight--bold;
    color: $color--heading-light;
    margin-bottom: 20px;
}

.services-request-callback-form__success-copy {
    font-size: 18px;
    font-weight: $font-weight--medium;
    line-height: 1.43;
    color: $color--text;
}

.services-request-callback-form__success-icon {
    width: 70px;
}


.services-request-callback-form__form {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 10px;

    .form-group {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        textarea {
            width: 100%;
            min-height: 60px;
            border-radius: 16px;
        }

        input, select, textarea {
            background-color: $color--panel;
            border-radius: 16px;
        }

        label {
            font-weight: 400;
            color: $color--text-light;
        }

        &.message {
            grid-column: span 3;
        }
    }

    .submit {
        grid-column: span 1;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        button {
            width: 100%;
            height: 67px;
        }
    }

    &.hidden {
        display: none;
    }
}


.services-request-callback-form__form-submit {}

.services-request-callback-form--success {

    .services-request-callback-form__success {
        display: flex;
        border-bottom: none;
    }

    .services-request-callback-form__error,
    .services-request-callback-form__heading,
    .services-request-callback-form__form {
        display: none;
    }

}

.services-request-callback-form--error {}


@media screen and (max-width: 1248px) {
    .services-request-callback-form__form {
        grid-template-columns: repeat(2, 1fr);

        .form-group.message {
            grid-column: span 2;
        }

        .submit {
            grid-column: span 2;
        }
    }
}


@media screen and (max-width: 700px) {
    .services-request-callback-form__form {
        grid-template-columns: repeat(1, 1fr);

        .form-group.message {
            grid-column: span 1;
        }

        .submit {
            grid-column: span 1;
        }
    }
}
