.review-card {
    background-color: $color--panel;
    border: 1px solid $color--brand-primary-light;
    border-radius: 20px;
    border-top-left-radius: 0px;
}

.review-card__inner {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.review-card__name {
    font-size: 15px;
    font-weight: $font-weight--medium;
    color: $color--heading;
    margin-bottom: 5px;
}

.review-card__quote {
    font-size: 15px;
    font-weight: $font-weight--regular;
    color: $color--text;
    margin-bottom: 10px;
}

.review-card__rating {
    display: flex;
    align-items: center;

    img {
        width: 20px;

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}
