.team-member-card-round {
    position: relative;
}

.team-member-card-round__photo {
    @include bgImage();
    background-position: center;
    width: 180px;
    height: 180px;
    display: block;
    margin: auto;
    border-radius: 50%;
    background-color: $color--panel-border;
}

.team-member-card-round__inner {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &:not(:last-of-type) {
        border-bottom: 1px solid $color--panel-border;
    }
}

.team-member-card-round__name {
    display: block;
    font-size: 22px;
    font-weight: $font-weight--bold;
    color: $color--heading-brand;
    margin-bottom: 5px;
    text-align: center;

    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.team-member-card-round__position {
    display: block;
    font-size: 16px;
    font-weight: $font-weight--medium;
    color: $color--text;
    margin-bottom: 5px;
    text-align: center;
}
