// Brand colors
$color--aticus-green: #56B464;
// $color--aticus-green: #32af44;
// $color--aticus-green: #18AA22; // Works nice with dark BG
// $color--aticus-green: #10a81a;
$color--aticus-green-contrast: #86cf72;
$color--aticus-green-light: #D5EACD;
$color--aticus-blackish: #333A45;
$color--aticus-black: #1D1D1B;
$color--aticus-white: #FFFFFF;
$color--aticus-grey: #FCFCFC;
$color--aticus-grey-2: #767974;
$color--aticus-grey-3: #c5ccc1;
$color--aticus-grey-4: #E1E1E1;
$color--aticus-grey-5: #F7F7F7;
$color--brand-primary: $color--aticus-green;
$color--brand-primary-light: $color--aticus-green-light;

// UI
$color--body: $color--aticus-white;

$color--panel: $color--aticus-white;
$color--panel-dark: $color--aticus-blackish;
$color--panel-brand: $color--brand-primary;
$color--panel-contrast: $color--aticus-grey;
$color--panel-border: $color--aticus-grey-3;
$color--panel-border-2: $color--aticus-grey-4;

$shadow--panel-up: 0px -4px 20px -10px rgba(0, 0, 0, 0.1);
$shadow--panel-up-hovered: 0px -7px 27px -13px rgba(0,0,0,0.20);
$shadow--panel-down: 0px 4px 20px -10px rgba(0, 0, 0, 0.1);
$shadow--panel-down-hovered: 0px 7px 27px -13px rgba(0,0,0,0.20);

// Typography
$color--heading: $color--aticus-black;
$color--heading-light: $color--aticus-white;
$color--heading-brand: $color--brand-primary;

$color--text: $color--aticus-black;
$color--text-light: $color--aticus-white;
$color--text-secondary: $color--aticus-grey-2;
$color--text-tertiary: #50504F;
$color--text-brand: $color--brand-primary;

$color--input-bg: #E1E1E1;
$color--input-placeholder: $color--aticus-blackish;
$color--input-text: $color--aticus-black;
$color--input-border: #E0E0E0;
