
/*
Request Callback Template
*/

.page-template-template-request-callback .container {
    @include wrap();
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1080px;
}


@media (min-width: 768px) {

    .page-template-template-request-callback .section {
        padding: 0px;
    }

    .page-template-template-request-callback .request-callback-content {
        padding-top: 100px;
        padding-bottom: 10px;
    }

}

.request-callback-header {
    padding-top: 20px;
    padding-bottom: 20px;
}

.request-callback-header__logo {
    max-width: 230px;
}


.request-callback-banner {
    position: relative;
    border-top: 20px solid #353C44;
    border-bottom: 20px solid #353C44;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.request-callback-banner__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
}

.request-callback-banner__top {
    position: relative;
    background-color: rgba(53, 60, 68, 0.8);
    padding-top: 10px;
    padding-bottom: 10px;
}

.request-callback-banner__top-heading {
    font-size: 30px;
    color: #ffffff;
    font-weight: 600;
    margin: 0px;
    max-width: 45vw;
}

.request-callback-banner__bottom {
    position: relative;
    background-color: rgba(53, 60, 68, 0.8);
    padding-top: 10px;
    padding-bottom: 10px;
}

.request-callback-banner__contact {}

.request-callback-banner__contact-heading {
    font-size: 30px;
    color: rgb(113, 185, 86);
    font-weight: 600;
    margin: 0px;
}

.request-callback-banner__contact-phone {
    font-size: 28px;
    color: #ffffff;
    font-weight: 600;
    margin: 0px;
    display: block;
    text-decoration: none;
    line-height: 1;
}

.request-callback-banner__contact-email {
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
    margin: 0px;
    display: block;
    text-decoration: none;
}

.request-callback-banner__contact-phone:hover,
.request-callback-banner__contact-email:hover {
    color: #ffffff;
    text-decoration: underline;
}


.request-callback-banner__form-container {
    position: relative;
}

.request-callback-banner__form {
    position: absolute;
    right: 30px;
    top: -87px;
    background-color: rgb(113, 185, 86);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 360px;
    z-index: 1;
}

/*
Overide WPCF7 auto BRs
*/
.request-callback-banner__form br {
    display: none !important;
}

.request-callback-banner__form-heading {
    font-size: 28px;
    color: #ffffff;
    font-weight: 600;
    margin: 0px;
    display: block;
    text-decoration: none;
    line-height: 1;
    margin-bottom: 10px;
}

.request-callback-banner__form-copy {
    font-size: 16px;
    font-weight: 500;
    color: #353c44;
    margin-bottom: 10px;
}


.request-callback-banner__form .wpcf7-form {
    border: none;
    padding: 0px;
    display: block;
    margin: 0px;
}

.request-callback-banner__form .wpcf7-form p {
    margin: 0px;
}

.request-callback-banner__form .wpcf7-textarea {
    height: 150px;
}

.request-callback-banner__form .wpcf7-form .wpcf7-response-output {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 15px;
    color: white;
    font-weight: 700;
    margin-top: 10px;
}

.request-callback-banner__form .wpcf7-form .wpcf7-not-valid-tip {
    display: none;
}

.request-callback-banner__form .wpcf7-form label {
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 5px;
    color: #ffffff;
}

.request-callback-banner__form .wpcf7-form input[type=text],
.request-callback-banner__form .wpcf7-form input[type=email],
.request-callback-banner__form .wpcf7-form input[type=tel],
.request-callback-banner__form .wpcf7-form textarea {
    width: 100%;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px 10px;
    border: 1px solid transparent;
}


.request-callback-banner__form .wpcf7-form .wpcf7-not-valid {
    border: 1px solid red !important;
}

.request-callback-banner__form .wpcf7-form .wpcf7-submit {
    width: 100%;
    border-radius: 4px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: $color--panel-dark;
    font-size: 15px;
    font-weight: $font-weight--bold;
    text-align: center;
    color: $color--text-light;
    padding: 20px 10px;
}

.request-callback-banner__form .wpcf7-form .ajax-loader {
    display: none !important;
}


@media screen and (max-width: 720px) {

    .page-template-template-request-callback .container {
        padding-left: 15px;
        padding-right: 15px;
    }


    .request-callback-banner {
        min-height: 330px;
    }

    .request-callback-banner__top-heading {
        max-width: none;
    }

    .request-callback-banner__form {
        left: 30px;
        width: calc(100% - 60px);
        top: 350px;
        max-width: none;
    }

    .page-template-template-request-callback .request-callback-content {
        padding-top: 710px;
    }

}
