.location-card {
    border: 1px solid $color--panel-border-2;
    background: $color--panel;
    box-shadow: $shadow--panel-down;
    border-radius: 18px;
    border-top-left-radius: 0px;
    overflow: hidden;

    transition: box-shadow 0.4s ease-in-out;

    &:hover {
        box-shadow: $shadow--panel-down-hovered;
    }
}

.location-card__inner {
    width: 100%;
}

.location-card__map {
    position: relative;
    height: 220px;

    iframe {
        @include absoluteFit();
        width: 100%;
    }

}

.location-card__details {
    flex-basis: 55%;
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 22px;
    padding-right: 22px;
}

.location-card__heading {
    display: inline-block;
    font-size: 24px;
    color: $color--heading;
    text-decoration: none;
    margin-bottom: 15px;

    &:hover {
        text-decoration: underline;
    }
}

.location-card__description {
    font-weight: $font-weight--bold;
    font-size: 15px;
    color: $color--text-tertiary;
    line-height: 23px;
    margin-bottom: 30px;
}

.location-card__address {
    font-size: 16px;
    font-weight: $font-weight--medium;
    color: $color--text-tertiary;
    line-height: 24px;
    font-style: normal;
    height: 100px;
    display: flex;

    img {
        width: 20px;
        height: 27px;
        margin-right: 16px;
        margin-left: 3px;
        margin-top: 3px;
    }

    a {
        color: $color--text-tertiary;
        text-decoration: none;
    }

    &:hover {
        a {
            text-decoration: underline;
        }
    }

}

.location-card__phone,
.location-card__email {
    display: flex;
    align-items: center;

    img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
    }

    a {
        font-size: 20px;
        font-weight: $font-weight--medium;
        color: $color--text;
        text-decoration: none;
    }

    &:hover {
        a {
            text-decoration: underline;
        }
    }

}

.location-card__phone {
    margin-bottom: 17px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 17px;
        margin-left: 2px;
    }
}

.location-card__email {
    margin-bottom: 15px;

    img {
        width: 26px;
        height: 26px;
        margin-right: 13px;
    }

}


@media screen and (max-width: 768px) {

    .location-card__inner {
        flex-direction: column;
    }

    .location-card__map {
        height: 190px;
    }

    .location-card__details {
        padding-top: 30px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .location-card__heading {
        font-size: 21px;
        margin-bottom: 10px;
    }

    .location-card__address {
        font-size: 17px;
        margin-bottom: 30px;
    }

}


@media screen and (max-width: 450px) {

    .location-card__details {
        padding-top: 25px;
        padding-bottom: 15px;
        padding-left: 23px;
        padding-right: 23px;
    }

}


@media screen and (max-width: 400px) {

    .location-card__details {
        padding-top: 22px;
        padding-bottom: 15px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .location-card__phone a, .location-card__email a {
        font-size: 20px;
    }

    .location-card__address {
        font-size: 16px;
        line-height: 23px;
        height: 90px;
        margin-bottom: 20px;
    }

}
