.page--team-member {

    .team-member-block {}

    .team-member-block__inner {
        @include wrap();
        display: flex;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .team-member-block__left {
        margin-right: 50px;
        flex: 1;
    }

    .team-member-block__heading {
        font-size: 32px;
        font-weight: $font-weight--bold;
        color: $color--heading-brand;
        margin-bottom: 5px;
    }

    .team-member-block__position {
        font-size: 24px;
        font-weight: $font-weight--medium;
        color: $color--heading;
        margin-bottom: 30px;
    }

    .team-member-block__bio {}

    .team-member-block__right {
        flex-basis: 420px;
        padding-top: 50px;
    }

    .team-member-block__contact-heading {
        font-size: 20px;
        font-weight: $font-weight--bold;
        color: $color--heading-brand;
        margin-bottom: 20px;
    }

    .team-member-block__image {
        @include bgImage();
        width: 100%;
        height: 20vw;
        min-height: 240px;
        max-height: 290px;
        min-width: 320px;
        margin-bottom: 20px;
    }

    .team-member-block__contact {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
            font-size: 16px;
            font-weight: $font-weight--medium;
            width: 70px;
            color: $color--text;
        }

        a {
            font-size: 18px;
            font-weight: $font-weight--medium;
            color: $color--text;
            text-decoration: none;
        }

    }


    @media screen and (max-width: 768px) {

        .team-member-block__inner {
            display: block;
        }

        .team-member-block__left {
            margin-right: 0px;
        }

        .team-member-block__image {
            min-width: 0px;
        }

        .team-member-block__contact {

            span {
                font-size: 15px;
            }

            a {
                font-size: 16px;
            }
        }

    }

}
