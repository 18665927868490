body.template--private-client-matters-free-consultation {
    #calendly_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
        #calendly_widget {
            width: 100%;
            height: 1000px;
            max-width: 500px;
        }
    }
}
