body.page--post-single {

    .content-split {
        border-top: 1px solid $color--panel-border;
    }

    .content-split__inner {
        @include wrap();
        display: flex;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .content-split__main {
        margin-right: 50px;
        flex: 1;
    }

    .content-split__aside {
        flex-basis: 320px;

        .team-member-card {
            margin-bottom: 30px;
        }

    }

    .content-split__aside-heading {
        font-size: 25px;
        font-weight: $font-weight--bold;
        color: $color--heading-brand;
        margin-bottom: 30px;
    }


    .consultation-cta-block {
        border-bottom: none;
    }


    @media screen and (max-width: 1200px) {

        .content-split__main {
            margin-right: 8vw;
        }

    }

    @media screen and (max-width: 992px) {

        .content-split__inner {
            display: block;
        }

        .content-split__main {
            margin-right: 0px;
        }

        .content-split__aside {
            border-top: 1px solid $color--panel-border;
            margin-top: 60px;
            padding-top: 50px;
        }

        .content-split__aside .team-member-card {
            margin-bottom: 60px;
            max-width: 440px;
        }

    }

}
