.video-block {
    margin-top: 50px;
    margin-bottom: 50px;
}

.video-block__inner {
    @include wrap();
    display: flex;
}

.video-block__video {
    margin-right: 50px;
    flex-basis: 50%;

    iframe {
        width: 100%;
        height: 50vw;
        max-height: 350px;
    }
}

.video-block__content {
    flex-basis: 50%;
}

.video-block__heading {
    font-size: 35px;
    font-weight: $font-weight--medium;
    line-height: 1.2;
    color: $color--heading;
    margin-top: 10px;
    margin-bottom: 30px;
}

.video-block__copy {
    font-size: 18px;
    font-weight: $font-weight--regular;
    line-height: 1.4;
    margin-bottom: 20px;
    color: $color--text;
}

.video-block__link {}


@media screen and (max-width: 992px) {

    .video-block__video {
        margin-right: 30px;
    }

    .video-block__heading {
        font-size: 30px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

    .video-block__copy {
        font-size: 16px;
        margin-bottom: 16px;
    }

}


@media screen and (max-width: 768px) {

    .video-block__inner {
        flex-direction: column-reverse;
    }

    .video-block__video {
        margin-right: 0px;
        margin-top: 40px;
    }

}


@media screen and (max-width: 520px) {

    .video-block__heading {
        font-size: 27px;
    }

}
