body.page--404 {

    .error-notice {}

    .error-notice__inner {
        @include wrap();
        // max-width: 992px;
        padding-top: 13vh;
        padding-bottom: 22vh;
    }

    .error-notice__heading {
        font-size: 35px;
        font-weight: $font-weight--medium;
        color: $color--heading;
        margin-bottom: 20px;
        max-width: 660px;
    }

    .error-notice__copy {
        font-size: 18px;
        font-weight: $font-weight--medium;
        color: $color--text;
        margin-bottom: 40px;
    }

    .error-notice__btns {
        display: flex;
        align-items: center;
    }

    .error-notice__cta {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }


    @media screen and (max-width: 440px) {

        .error-notice__btns {
            flex-wrap: wrap;
        }

        .error-notice__cta {
            flex-basis: 100%;

            &:not(:last-child) {
                margin-right: 0px;
                margin-bottom: 10px;
            }
        }

    }

}
