@mixin wrap() {

    $horizontalPadding: 30px;

    max-width: calc(1220px + #{$horizontalPadding} + #{$horizontalPadding});
    margin: auto;
    width: 100%;
    padding-left: $horizontalPadding;
    padding-right: $horizontalPadding;

    @media screen and (max-width: 768px) {
        $horizontalPadding: 25px;
        padding-left: $horizontalPadding;
        padding-right: $horizontalPadding;
    }

    @media screen and (max-width: 480px) {
        $horizontalPadding: 18px;
        padding-left: $horizontalPadding;
        padding-right: $horizontalPadding;
    }

}



@mixin absoluteFit() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


@mixin fixedFit() {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


@mixin bgImage() {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


@mixin bgBlurLight() {
    background-color: rgba($color--panel-brand, 0.8);
    backdrop-filter: blur(15px);
}

@mixin bgBlur() {
    background-color: rgba($color--aticus-grey-4, 0.8);
    backdrop-filter: blur(0px);
}

@mixin bgBlurDark() {
    background-color: rgba($color--panel-dark, 0.8);
    backdrop-filter: blur(0px);
}


@mixin brandGradient {
    background: $color--aticus-green;
    background: linear-gradient(90deg, $color--aticus-green 0%, $color--aticus-green-contrast 84%);
}

@mixin brandGradientReversed {
    background: $color--aticus-green-contrast;
    background: linear-gradient(90deg, $color--aticus-green-contrast 0%, $color--aticus-green 84%);
}

@mixin brandGradientCornered {
    background: $color--aticus-green;
    background: linear-gradient(25deg, $color--aticus-green 45%, $color--aticus-green-contrast 120%);
}

@mixin brandGradientCorneredHovered {
    background: $color--aticus-green;
    background: linear-gradient(25deg, $color--aticus-green 35%, $color--aticus-green-contrast 110%);
}
