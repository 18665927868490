.site-header--simple {
    height: auto !important;
}

.flc-hero {
    background-color: #777;
    position: relative;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 150px;
}

.flc-hero__bg {
    @include absoluteFit();
    @include bgImage();
    width: 100%;
    height: 100%;
}

.flc-hero__bg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color--aticus-blackish;
    opacity: 0.2;
}

.flc-hero__content {
    position: absolute;
    left: 0;
    right: 0;
}

.flc-hero__content-section {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
}

.flc-hero__content-bg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include brandGradientCornered();
    @include brandGradientCorneredHovered();
    opacity: 0.92;
    width: 52vw;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
}

.flc-hero__content-inner {
    @include wrap();
    max-width: 1200px;
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
    // padding-left: 30px;
    // max-width: 60%;
}

.flc-hero__content-heading {
    font-size: 43px;
    font-weight: $font-weight--bold;
    line-height: 1.26;
    color: $color--heading-light;
    margin-bottom: 20px;
    width: 50%;
}

.flc-hero__content-copy {
    font-size: 21px;
    font-weight: $font-weight--medium;
    line-height: 1.26;
    color: $color--heading-light;
    width: 50%;
}

.flc-hero__contact {
    position: relative;
}

.flc-hero__contact-inner {
    @include wrap();
    max-width: 1200px;
    display: flex;
    justify-content: flex-end;

    .request-callback-card-form {
        max-width: 350px;
    }
}


@media screen and (max-width: 992px) {

    .flc-hero__content-heading {
        font-size: 37px;
        margin-bottom: 9px;
    }

    .flc-hero__content-copy {
        font-size: 20px;
    }

}


@media screen and (max-width: 768px) {

    .flc-hero {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .flc-hero__content {
        position: relative;
    }

    .flc-hero__content-bg-overlay {
        width: 90vw;
    }

    .flc-hero__content-heading {
        width: 80vw;
    }

    .flc-hero__content-copy {
        width: 80vw;
    }

    .flc-hero__contact-inner {
        justify-content: flex-start;
        padding-left: 0px;
        padding-right: 0px;
    }


    .flc-hero__contact-form-bg {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        width: 100%;
    }

}


@media screen and (max-width: 550px) {

    .flc-hero__content-heading {
        font-size: 30px;
        margin-bottom: 9px;
    }

    .flc-hero__content-copy {
        font-size: 17px;
    }

}


@media screen and (max-width: 430px) {

    .flc-hero__content-bg-overlay {
        width: 95vw;
    }

    .flc-hero__content-heading {
        width: 85vw;
    }

    .flc-hero__content-copy {
        width: 85vw;
    }

    .flc-hero__contact-form {
        max-width: 95vw;

        .wpcf7 {
            max-width: 85vw;
        }
    }

}





.flc-services {}

.flc-services__inner {
    @include wrap();
    max-width: 1200px;
    padding-top: 60px;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flc-services__service {}

.flc-services__service-icon {
    display: block;
    margin: auto;
    margin-bottom: 20px;
    width: 180px;
    height: 180px;
}

.flc-services__service-heading {
    font-size: 25px;
    font-weight: $font-weight--bold;
    line-height: 1.4;
    color: $color--heading;
    text-align: center;
}


@media screen and (max-width: 992px) {

    .flc-services__service-icon {
        width: 170px;
    }

    .flc-services__service-heading {
        font-size: 22px;
    }

}


@media screen and (max-width: 768px) {

    .flc-services__service-icon {
        width: 120px;
    }

    .flc-services__service-heading {
        font-size: 19px;
    }

}


@media screen and (max-width: 600px) {

    .flc-services__inner {
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 60px;
    }

    .flc-services__service {

        &:not(:last-child) {
            margin-bottom: 30px;
        }

    }

    .flc-services__service-icon {
        width: 140px;
    }

    .flc-services__service-heading {
        font-size: 19px;
    }

}







.flc-help {
    position: relative;
    @include brandGradientCorneredHovered();
}

.flc-help__inner {
    @include wrap();
    max-width: 1200px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.flc-help__content {
    max-width: 50%;
    padding-right: 30px;
}

.flc-help__heading {
    font-size: 32px;
    font-weight: $font-weight--bold;
    line-height: 1.4;
    margin-bottom: 25px;
    color: $color--heading-light;
}

.flc-help__copy {
    font-size: 19px;
    font-weight: $font-weight--medium;
    line-height: 1.4;
    margin-bottom: 25px;
    color: $color--heading-light;
}

.flc-help__btn {

    small {
        margin-right: 5px;
    }
}

.flc-help__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    width: 50%;
}


.template--family-law {
    .site-footer__inner,
    .creditation-logos__inner {
        max-width: 1200px;
    }
}



@media screen and (max-width: 768px) {

    .flc-help {
        display: flex;
        flex-direction: column-reverse;
    }

    .flc-help__inner {
        padding-top: 30px;
    }

    .flc-help__content {
        max-width: 100%;
    }

    .flc-help__bg {
        position: relative;
        width: 100%;
        height: 60vw;
    }

    .flc-help__heading {
        font-size: 29px;
        margin-bottom: 20px;
    }


    .flc-help__copy {
        font-size: 20px;
        margin-bottom: 20px;
    }

}
