html {
    scroll-behavior: smooth;
}

.brand-logos {
    padding-top: 50px;
    padding-bottom: 40px;
}

.brand-logos__inner {
    @include wrap();
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
        
    @media (max-width: 400px) {
        flex-direction: column;
    }
}

.brand-logos__cruk-img,
.brand-logos__brand-logo {
    max-height: 60px;
    max-width: 300px;
        
    @media (max-width: 600px) {
        max-height: 50px;
        max-width: 250px;
    }
        
    @media (max-width: 500px) {
        max-height: 50px;
        max-width: 200px;
    }
        
    @media (max-width: 400px) {
        max-height: 60px;
        max-width: 100%;
    }
}

.brand-logos__plus {
    font-weight: $font-weight--medium;
    font-size: 20px;
    color: $color--text;
    text-align: center;
    line-height: 28px;
    margin-left: 15px;
    margin-right: 15px;
        
    @media (max-width: 700px) {
        margin-left: 10px;
        margin-right: 10px;
    }
}


.intro-content {}

.intro-content__inner {
    @include wrap();
    max-width: 520px;
}

.intro-content__title {
    font-weight: $font-weight--bold;
    font-size: 29px;
    color: $color--heading;
    text-align: center;
    line-height: 35px;
    margin-bottom: 22px;
}

.intro-content__description {
    font-weight: $font-weight--medium;
    font-size: 16px;
    color: $color--text;
    text-align: center;
    line-height: 25px;
    margin-bottom: 40px;
}


.register-form {
    padding-bottom: 60px;
    border-bottom: 1px solid $color--panel-border-2;
    margin-bottom: 40px;
}

.register-form__inner {
    @include wrap();
    max-width: 500px;

    .input {
        width: 100%;

        input, select {
            width: 100%;
            @extend %input;
        }

        &:not(:last-child) {
            margin-bottom: 14px;
        }

    }

    .submit-btn {
        width: 100%;
    }

}


.help-steps {
    padding-bottom: 55px;
    border-bottom: 1px solid $color--panel-border-2;
}

.help-steps__inner {
    @include wrap();
    max-width: 520px;
}

.help-steps__heading {
    font-weight: $font-weight--bold;
    font-size: 20px;
    color: $color--heading;
    text-align: center;
    margin-bottom: 25px;
}

.help-steps__list {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    margin-bottom: 35px;
}

.help-steps__list-item {
    display: flex;
    align-items: flex-start;

    &:not(:last-child) {
        margin-bottom: 18px;
    }

}

.help-steps__number {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    font-weight: $font-weight--bold;
    font-size: 19px;
    color: $color--heading-light;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color--brand-primary;
    border-radius: 50%;
}

.help-steps__step {
    font-weight: $font-weight--medium;
    font-size: 18px;
    color: $color--text;
    line-height: 25px;
    flex: 1;
    min-height: 45px;
}

.help-steps__button {
    display: block;
    margin: auto;
    max-width: 200px;
}


.smallprint {
    padding-top: 45px;
    padding-bottom: 45px;
}

.smallprint__inner {
    @include wrap();
    max-width: 520px;
}

.smallprint__text {
    font-weight: $font-weight--medium;
    font-size: 15px;
    color: $color--text-tertiary;
    line-height: 21px;
}

#cruk_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #title {
        font-size: 30px;
        font-weight: bold;
        white-space: initial;
        text-align: center;
        margin-bottom: 20px;
        
        @media (max-width: 320px) {
            font-size: 25px;
        }
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        max-width: 500px;
        flex-wrap: nowrap;
        width: 100%;
    }

    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        
        @media (max-width: 768px) {
            font-size: 12px;
        }
    }
    
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }
    
    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }
    
    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
    }
    
    .stepper-item.active {
        font-weight: bold;
    }
    
    .stepper-item.completed .step-counter {
        background-color: #56B464;
    }
    
    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #56B464;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }
    
    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
}

#cruk_error {
    background-color: red;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px 0px;

    #error_message {
        color: #fff;
        font-weight: bold;
    }
}

#cruk_error.hidden {
    display: none;
}

#cruk_registered {
    background-color: #56B464;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px 0px;

    #wrapper {
        background-color: #f4f4f4;
        padding: 15px;
        border-radius: 16px;
        border-top-right-radius: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        #thank_you {
            font-size: 25px;
            font-weight: bold;
            white-space: initial;
            text-align: center;
        }

        #unique_code_wrapper {
            padding: 15px;
            font-size: 18px;

            #brand_offer_code {
                display: block;
                font-weight: bold;
                text-align: center;
                line-height: 30px;
                font-size: 35px;
            }
        }

        #how_to_use {
            font-size: 14px;
            text-align: center;

            @media only screen and (max-width: 400px) {
                #the_code {
                    display: none;
                }
            }
        }

        #register_again {
            font-size: 14px;
            text-align: center;
            margin-top: 3px;

            #here {
                cursor: pointer;
                color: #56B464;
                font-weight: bold;
            }
        }
    }
}

#cruk_registered.hidden {
    display: none;
}

#cruk_calendly {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    #calendly_widget {
        width: 100%;
        height: 800px;
        max-width: 500px;
    }
}

#cruk_calendly.hidden {
    display: none;
}

#cruk_registration_form.hidden {
    display: none;
}