.mobile-menu {
    @include absoluteFit();
    position: fixed;
    z-index: 0;

    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in;

    .mobile-menu__related-services {
        display: none;
    }
}

.mobile-menu__bg {
    @include absoluteFit();
    @include bgBlurLight();
    cursor: pointer;
}

.mobile-menu__main {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60vw;
    max-width: 500px;
    background-color: $color--panel;
    overflow: auto;

    transform: translateX(100%);
    transition: all 0.2s ease-in;
}


$mobile-menu-close-icon--width: 25px;
$mobile-menu-close-icon--height: 4px;
.mobile-menu__close {
    font-size: 16px;
    font-weight: $font-weight--bold;
    color: $color--heading;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    height: $mobile-menu-close-icon--width;
    width: $mobile-menu-close-icon--width;
    align-items: center;
    justify-content: center;

    opacity: 0.7;

    &:hover {
        transform: scale(1.02);
        opacity: 1;
    }
}

.mobile-menu__close-bar {
    width: $mobile-menu-close-icon--width;
    height: $mobile-menu-close-icon--height;
    border-radius: 2px;
    background-color: $color--panel-dark;

    &:first-child {
        transform: rotate(-45deg);
    }

    &:last-child {
        position: relative;
        top: -$mobile-menu-close-icon--height;
        transform: rotate(45deg);
    }

}

.mobile-menu__inner {
    padding-top: 15vh;
    padding-bottom: 30px;
}

.mobile-menu__logo {
    display: block;
    margin: auto;
    margin-bottom: 60px;
    width: 200px;

    img {
        width: 100%;
    }
}

.mobile-menu__nav {

    .header-main-menu {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
    }

    // Top level items
    .header-main-menu__item {
        position: relative;
    }

    // Specific item (where x = post_id)
    .header-main-menu__item--x {}

    // Parent item
    .header-main-menu__item--parent {}

    // Active page item
    .header-main-menu__item--active {}

    // Parent of the current active page
    .header-main-menu__item--parent--active {}

    // Ancestor of the current active page
    .header-main-menu__item--ancestor--active {}

    // Link
    .header-main-menu__link {
        display: block;
        font-size: 20px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        text-decoration: none !important;
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 12px;
        padding-bottom: 12px;

        &:hover {

            background-color: darken($color--panel, 5%);

        }

    }


    // Trigger
    .header-main-menu__sub-trigger {
        display: none;
    }


    // sub menu class
    .header-main-menu__sub-list {
        display: none;
    }

}


.mobile-menu__socials {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.mobile-menu__social {
    width: 25px;
    text-decoration: none;

    img {
        width: 100%;
        display: block;
    }

    &:not(:last-child) {
        margin-right: 13px;
    }
}

.mobile-menu__small-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 10px;
}

.mobile-menu__small-nav-link {
    font-size: 15px;
    font-weight: $font-weight--bold;
    color: $color--text-secondary;
    margin-bottom: 10px;

    &:not(:last-child) {
        margin-right: 10px;
    }

}


.mobile-menu__related-services {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - 500px);
    max-width: 1000px;
    bottom: 0px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    height: 100%;

    opacity: 0;
    visibility: hidden;
    transform: scale(0.95) translateY(-20px);
    transition: all 0.2s ease-in-out;

    .menu-item-card {
        flex-basis: calc(50% - 10px);
        margin-bottom: 20px;
        border: none !important;
        background-color: $color--panel;
        height: auto;

        &:not(:nth-child(2n)) {
            margin-right: 20px;
        }
    }

    .team-member-card {
        flex-basis: calc(50% - 15px);
        margin-bottom: 30px;
        border: none;

        &:not(:nth-child(2n)) {
            margin-right: 30px;
        }
    }


    .news-card {
        flex-basis: calc(50% - 15px);
        margin-bottom: 30px;

        &:not(:nth-child(2n)) {
            margin-right: 30px;
        }
    }

    .location-card {
        margin-bottom: 30px;
        background-color: $color--panel;
    }

}

.mobile-menu__related-services--business {
    // height: fit-content;
}

.mobile-menu--related-services-business-visible {

    .header-main-menu__item--toggle-mobile-menu-business-content {
        .header-main-menu__link {
            background-color: darken($color--panel, 5%);
        }
    }

    .mobile-menu__related-services--business {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }

}

.mobile-menu__related-services--personal {
    // height: fit-content;
}

.mobile-menu--related-services-personal-visible {

    .header-main-menu__item--toggle-mobile-menu-personal-content {
        .header-main-menu__link {
            background-color: darken($color--panel, 5%);
        }
    }

    .mobile-menu__related-services--personal {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }

}

.mobile-menu--related-services-team-visible {

    .header-main-menu__item--toggle-mobile-menu-team-content {
        .header-main-menu__link {
            background-color: darken($color--panel, 5%);
        }
    }

    .mobile-menu__related-services--team {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }

}

.mobile-menu--related-services-blog-visible {

    .header-main-menu__item--toggle-mobile-menu-blog-content {
        .header-main-menu__link {
            background-color: darken($color--panel, 5%);
        }
    }

    .mobile-menu__related-services--blog {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }

}

.mobile-menu__related-services--contact {
    display: block !important;
}

.mobile-menu--related-services-contact-visible {

    .header-main-menu__item--toggle-mobile-menu-contact-content {
        .header-main-menu__link {
            background-color: darken($color--panel, 5%);
        }
    }

    .mobile-menu__related-services--contact {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translateY(0);
    }

}


@media screen and (max-width: 1280px) {

    .mobile-menu__main {
        max-width: 400px;
    }

    .mobile-menu__related-services {
        width: calc(100vw - 400px);
        padding-top: 30px;
        padding-left: 30px;
        padding-right: 30px;

        .team-member-card {
            flex-basis: 100%;

            &:not(:nth-child(2n)) {
                margin-right: 0px;
            }
        }

    }


}

@media screen and (max-width: 992px) {

    .mobile-menu__related-services {

        .menu-item-card {
            flex-basis: 100%;

            &:not(:nth-child(2n)) {
                margin-right: 0px;
            }
        }

        .news-card {
            flex-basis: 100%;

            &:not(:nth-child(2n)) {
                margin-right: 0px;
            }
        }

    }


}


@media screen and (max-width: 768px) {

    .mobile-menu__related-services {
        display: none !important;
    }

}

.mobile-menu--open {
    opacity: 1;
    visibility: visible;
    z-index: 100;

    .mobile-menu__related-services {
        display: flex;
    }

    .mobile-menu__main {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

}


@media screen and (max-width: 580px) {

    .mobile-menu__main {
        width: 80vw;
    }

}


@media screen and (max-width: 400px) {

    .mobile-menu__main {
        width: 95vw;
    }

}
