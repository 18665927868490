.mid-content-cta {
    background-color: $color--brand-primary-light;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-top: 30px;
    margin-bottom: 30px;

    br {
        display: none !important;
    }

    p {
        margin: 0px;
    }
}

.mid-content-cta__text {
    font-size: 16px;
    font-weight: $font-weight--medium;
    color: $color--text;
    flex-basis: 50%;
    margin: 0px !important;
}

.mid-content-cta__phone {
    flex-basis: 180px;
    a {
        font-size: 19px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        text-decoration: none !important;
    }
}

.mid-content-cta__btn {
    flex-basis: 200px;
}


@media screen and (max-width: 1300px) {

    .mid-content-cta {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .mid-content-cta__text {
        flex-basis: 100%;
        text-align: center;
        order: 0;
        margin-bottom: 15px !important;
    }

    .mid-content-cta__phone {
        order: 2;
        margin-left: 30px;
    }

}
