.generic-title-block {
    position: relative;
}

.generic-title-block__bg {
    @include absoluteFit();
    @include bgImage();
    background-image: url('/wp-content/themes/aticus2021/assets/dist/images/ui/masthead.png');
}

.generic-title-block__inner {
    @include wrap();
    position: relative;
    padding-top: 60px;
    padding-bottom: 70px;
}

.generic-title-block__heading {
    font-size: 40px;
    font-weight: $font-weight--medium;
    line-height: 1.2;
    color: $color--heading-light;
    text-align: center;
}
