.dev-tools {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $color--panel-dark;
    z-index: 10;
}

.dev-tools__inner {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.dev-tools__menu {

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
            &:not(:last-child) {
                margin-right: 15px;
            }
        }

        a {
            font-size: 14px;
            font-weight: $font-weight--medium;
            color: $color--text-light;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }


    }

}
