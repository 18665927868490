.services-block {
    @include wrap();
    margin-top: 50px;
    margin-bottom: 50px;
}

.services-block__inner {
    background-color: $color--panel-dark;
    border-radius: 30px;
    border-top-left-radius: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 40px;
    padding-right: 40px;
}

.services-block__heading {
    font-size: 24px;
    font-weight: $font-weight--medium;
    color: $color--text-light;
    text-align: center;
    margin-bottom: 40px;
}

.services-block__services {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.services-block__service {
    flex-basis: 30%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    text-decoration: none;

    img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }

    span {
        font-size: 18px;
        color: $color--text-light;
        font-weight: $font-weight--medium;
        position: relative;
        text-transform: capitalize;

        &:after {
            content: '';
            position: absolute;
            width: 98%;
            height: 3px;
            border-radius: 2px;
            bottom: -10px;
            left: 0;
            right: 0;
            margin: auto;
            background-color: $color--brand-primary;
            transition: all 0.2s ease;
        }

    }

    &:hover {

        span:after {
            bottom: -12px;
        }

    }

}

.services-block__cta {
    margin: auto;
    display: block;
    max-width: 200px;
    margin-top: 30px;
}


// @media screen and (max-width: 1080px)

@media screen and (max-width: 1080px) {

    .services-block__inner {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .services-block__service span {
        font-size: 16px;

        &:after {
            bottom: -9px;
        }
    }

}


@media screen and (max-width: 768px) {

    .services-block__service {
        flex-basis: 50%;
        margin-bottom: 20px;
    }

}


@media screen and (max-width: 650px) {

    .services-block__services {
        justify-content: center;
    }

    .services-block__service {
        flex-basis: 310px;
    }

}


@media screen and (max-width: 520px) {

    .services-block {
        padding-left: 0px;
        padding-right: 0px;
    }

    .services-block__inner {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0px;
    }

}
