.news-slider-block {
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 50px;
}

.news-slider-block__inner {
    @include wrap();
    overflow: visible;
}

.news-slider-block__heading {
    font-size: 28px;
    font-weight: $font-weight--medium;
    color: $color--heading;
    text-align: center;
    margin-bottom: 40px;
}

.news-slider-block__slider {
    overflow: visible;

    .swiper-wrapper {
        overflow: visible;
        position: relative;
        height: auto;
    }

    .swiper-slide {
        height: auto;

        .news-card {
            transition: all 0.2s ease;
            position: relative;
            opacity: 0.1;
            transform: scale(0.8);
            border: 2px solid $color--brand-primary;
        }

    }

    .swiper-slide-active, .swiper-slide-next {

        .news-card {
            transform: scale(1);
            opacity: 1;
        }

    }

}

.news-slider-block__slider-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.news-slider-block__slider-nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: $color--panel-brand;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color: lighten($color--panel-brand, 3%);
    }

    &:active {
        background-color: lighten($color--panel-brand, 5%);
    }

    img {
        width: 60%;
    }

}

.news-slider-block__slider-nav-btn--prev {
    margin-right: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.news-slider-block__slider-nav-btn--next {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.news-slider-block__item {

    .news-card {
        border: none;
    }

}


@media screen and (max-width: 600px) {

    .news-slider-block__slider .swiper-slide .news-card {
        min-height: 380px;
    }


}
