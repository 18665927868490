%input {
    appearance: none;
    font-weight: $font-weight--medium;
    font-size: 15px;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-family: inherit;
    line-height: inherit;

    background-color: $color--input-bg;
    border: none;
    outline: none;
    color: $color--input-text;

    &::placeholder {
        color: $color--input-placeholder;
    }
}

%inputBrandContrast {
    @extend %input;

    background-color: $color--aticus-green-contrast;
    border: none;
    outline: none;
    color: $color--text-light;

    &::placeholder {
        color: #d6ffdc;
    }
}

%inputWhite {
    @extend %input;

    background-color: $color--panel;
    border: none;
    outline: none;
    color: $color--input-text;

    &::placeholder {
        color: rgba($color--input-placeholder, 0.5);
    }
}

.form-group {
    label {
        font-size: 17px;
        font-weight: $font-weight--medium;
        line-height: 1.43;
        color: $color--text;
        margin-bottom: 10px;
        display: block;
    }

    input,
    select {
        @extend %input;
        width: 100%;
    }

    textarea {
        @extend %input;
        width: 100%;
    }
}

.cda-register-form {
    input {
        margin-bottom: 20px;
    }

    button {
        width: 100%;
    }
}

[data-character-counter]:focus {
    outline: 3px solid #000 !important;

    &.cc-remaining-low {
        outline: 3px solid orange !important;
    }

    &.cc-remaining-zero {
        outline: 3px solid red !important;
    }
}
