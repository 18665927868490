body.user-login {
    margin: 0px;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .wrapper {
        background-color: #f4f4f4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100dvh;
        min-width: 100dvw;

        .container {
            border-radius: 5px;
            width: 100dvw;
            max-width: 400px;
            max-height: 500px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            gap: 10px;

            #logo {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;

                img {
                    width: 100%;
                    max-width: 250px;
                }
            }

            #error_message {
                display: none;
                
                &:not(:empty) {
                    display: block;
                    text-align: center;
                    padding: 15px 15px;
                    color: #721c24;
                    background-color: #f8d7da;
                    border: 1px solid #f5c6cb;
                }
            }

            input[type=text],
            input[type=password] {
                cursor: text;
                height: 50px;
                outline: 0;
                border: 0;
                background-color: #f4f4f4;
                border-bottom: 1px solid #56b464;
                padding-left: 5px;
            }

            input[type=submit] {
                height: 50px;
                outline: 0;
                border: 0;
                color: #000;
                background-color: #56b464;
                cursor: pointer;
            }
        }
    }
}
