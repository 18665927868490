.page--blog {

    .blog-header {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .blog-header__inner {
        @include wrap();
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .blog-header__heading {
        font-size: 28px;
        font-weight: $font-weight--medium;
        color: $color--heading;
    }

    .blog-header__category-select {

        select {
            border: 2px solid $color--brand-primary;
            padding: 10px 20px;
            font-size: 16px;
            font-weight: $font-weight--medium;
            color: $color--text;
            background-color: transparent;
            outline: none;
            border-radius: 10px;
            border-top-left-radius: 0px;
        }

    }

    .blog-posts {}

    .blog-posts__inner {
        @include wrap();
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;

        .news-card {
            flex-basis: calc(33.33333333% - 20px);
            margin-bottom: 30px;

            &:not(:nth-of-type(3n)) {
                margin-right: 30px;
            }

        }

    }

    .blog-posts__no-results {
        padding-top: 40px;
        padding-bottom: 40px;
        font-size: 20px;
        font-weight: $font-weight--medium;
        color: $color--heading;
        text-align: center;
    }


    .blog-posts__pagination {
        flex-basis: 100%;
    }


    @media screen and (max-width: 992px) {

        .blog-posts__inner .news-card {
            flex-basis: calc(50% - 15px);
            min-height: 340px;

            &:not(:nth-of-type(3n)) {
                margin-right: 0px;
            }

            &:nth-child(odd) {
                margin-right: 30px;
            }

        }

    }


    @media screen and (max-width: 640px) {

        .blog-posts__inner .news-card {
            flex-basis: 100%;
            min-height: 340px;

            &:nth-child(odd) {
                margin-right: 0px;
            }

        }

    }


}
