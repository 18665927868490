body.settings-campaign_landing_page {
    margin: 0px;

    [data-href] {
        cursor: pointer;
    }

    .text-center {
      text-align: center;
    }

    .section {
      padding: 10px;
      background-color: #fff;
      margin-bottom: 10px;

      &.hover[data-href]:hover {
        background-color: #56b464;
        color: #fff;
      }

      &#message {
        display: none;
        
        &:not(:empty) {
            display: block;
            text-align: center;
            padding: 15px 15px;
            color: #004085;
            background-color: #cce5ff;
            border: 1px solid #b8daff;

            &.error {
              color: #721c24;
              background-color: #f8d7da;
              border: 1px solid #f5c6cb;
            }
        }
    }

      #attributes {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;

        td, th {
          border: 1px solid #ddd;
          padding: 8px;
        }

        tr:nth-child(even){
          background-color: #f2f2f2;
        }

        tr:hover:not(.hover_off) {
          background-color: #ddd;
        }

        th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #56b464;
          color: #fff;
        }

        td {
          a {
            text-decoration: none;
          }

          input {
            width: 100%;
            padding: 10px;

            &[type=submit] {
                cursor: pointer;
            }
          }
        }
      }
    }
}