


/*========== Landing Page Css by Himanshu 4 feb 2021 ==========*/


/*=== Global Css ===*/

.page-template-template-landing-page a{
    text-decoration: none;
  }


  .testimonials_post_wrapper {
      margin-bottom: 30px;
  }

  .page-template-template-landing-page .container{
      max-width: 1195px;
      width: 100%;
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;
  }
  .ctrow {
      margin-left: -15px;
      margin-right: -15px;
  }
  .ctrow:before,
  .ctrow:after,
  .clearfix:before,
  .clearfix:after{
      content: " ";
      display: table;
  }
  .ctrow:after,
  .clearfix:after{
      clear: both;
  }
  .half-col {
      float: left;
      width: 50%;
      padding-left: 15px;
      padding-right: 15px;
  }

  /*=== Header css ===*/

  .ccustom-header {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 100;
      padding: 25px 0;
  }
  .ct-lp-connum {
      text-align: right;
  }
  .ct-lp-connum a{
      text-align: right;
      font-size: 32px;
      line-height: 60px;
      color: #fffefe;
      font-weight: bold;
  }
  .ct-lp-connum a:hover{
      color: #000;
  }

  /*=== Content css ===*/

  .lp-banner-area {
      position: relative;
      background-position: center center;
      background-size: cover;
      padding-top: 130px;
      padding-bottom: 50px;
      height: calc(100vh - 150px);
      display: flex;
      align-items: center;
  }
  .lp-banner-area:before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,.45);
      content: "";
  }
  .lp-banner-area > .container {
      position: relative;
      z-index: 1;
  }
  .ct-banner-sec .ct-banner-inner {
      display: table;
      width: 100%;
  }
  .ct-banner-sec .ct-banner-inner .bann-col {
      display: table-cell;
      vertical-align: bottom;
  }
  .ct-banner-sec .ct-banner-inner .bann-col.bann-col-left {
      vertical-align: middle;
  }
  .ct-banner-sec .ct-banner-inner .bann-col.ct-col-6{
      width: 60%;
  }
  .ct-banner-sec .ct-banner-inner .bann-col.ct-col-4{
      width: 40%;
  }
  .google_review{margin-top: 50px}
  .banner-form {
      text-align: right;
  }
  .banner-form .banner-wrapper {
      background-color: #fff;
      max-width: 365px;
      padding: 0;
      text-align: left;
      display: inline-block;
      border-radius: 10px;
  }
  .ct-cmn-btn{
      padding: 8px 45px;
    }
  .banner-form .banner-wrapper .banner-top-cnt {
      text-align: center;
      padding: 15px;
  }
  .banner-form .banner-wrapper .banner-top-cnt h2 {
      font-size: 24px;
      line-height: 1.3;
      color: #333e48;
      font-weight: 600;
      margin: 0 0 5px;
      text-align: center;
  }
  .banner-form .banner-wrapper .banner-top-cnt p {
      font-size: 14px;
      line-height: 18px;
      color: #333e48;
      margin: 0;
      font-weight: 500;
      text-align: left;
  }
  .banner-form .banner-wrapper .form-wrap {
      padding: 0 15px;
  }
  .ctmain-form form.wpcf7-form {
      margin: 0;
      padding: 0;
      border: 0 none;
      display: block;
  }
  .ctmain-form form.wpcf7-form label {
      font-size: 14px;
      line-height: 22px;
      color: #333e48;
      font-weight: bold;
      margin: 0 0 5px;
  }
  .ctmain-form form.wpcf7-form br {
      display: none;
  }
  .ctmain-form form.wpcf7-form span.wpcf7-form-control-wrap {
      display: block;
  }
  .ctmain-form form.wpcf7-form .form-control {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 28px;
      color: #333e48;
      border: 1px solid #333d47;
      height: 35px;
      width: 100%;
      border-radius: 0;
      background-color: #fff;
      padding: 2px 15px;
      transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
  }
  .ctmain-form form.wpcf7-form select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url(/wp-content/themes/aticus2021/assets/dist/images/ui/arr.png);
      background-position: right 15px top 13px;
      background-repeat: no-repeat;
  }
  .ctmain-form span.wpcf7-not-valid-tip {
      position: absolute;
      bottom: -6px;
      left: 0;
      font-size: 11px;
  }
  .ctmain-form .wpcf7 form.invalid .wpcf7-response-output,
  .ctmain-form .wpcf7 form.unaccepted .wpcf7-response-output{
    display: none !important;
  }
  .ctmain-form form.wpcf7-form .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #333e48;
    opacity: 1;
  }
  .ctmain-form form.wpcf7-form .form-control::-moz-placeholder { /* Firefox 19+ */
    color: #333e48;
    opacity: 1;
  }
  .ctmain-form form.wpcf7-form .form-control:-ms-input-placeholder { /* IE 10+ */
    color: #333e48;
    opacity: 1;
  }
  .ctmain-form form.wpcf7-form .form-control:-moz-placeholder { /* Firefox 18- */
    color: #333e48;
    opacity: 1;
  }
  .ctmain-form form.wpcf7-form .form-control:focus,
  .ctmain-form form.wpcf7-form .form-control:avtive,
  .ctmain-form form.wpcf7-form .form-control:hover,
  .ctmain-form form.wpcf7-form select.form-control:focus,
  .ctmain-form form.wpcf7-form select.form-control:avtive,
  .ctmain-form form.wpcf7-form select.form-control:hover{
      border: 1px solid #135e20 !important;
      border-radius: 0 !important;
      outline: none !important;
      box-shadow: none !important;
  }
  .ctmain-form form.wpcf7-form .btn-group {
      text-align: center;
      position: relative;
      background-color: #333e48;
      padding: 15px;
      margin-top: 15px;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
  }
  .ct-cmn-btn {
      background: #272d35;
      border: 0 none;
      text-align: center;
      display: inline-block;
      font-size: 16px;
      line-height: 1.4;
      color: #ffffff !important;
      padding: 15px 45px;
      cursor: pointer;
      outline: none;
      border-radius: 0;
      transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
  }
  .ctmain-form form.wpcf7-form .btn-group .ct-cmn-btn {
      margin: 0;
      background-color: #52bb63;
      padding: 18px 45px;
  }
  .ctmain-form form.wpcf7-form .btn-group .ct-cmn-btn:hover{
      background-color: #135e20;
  }

  .ctmain-form form.wpcf7-form .btn-group span.ajax-loader {
      position: absolute;
      bottom: -20px;
      left: 50%;
      margin-left: -10px;
  }
  .ct-cmn-btn:focus,
  .ct-cmn-btn:hover {
      background: #135e20;
      color: #fff;
  }
  .banner-left-cnt {
      max-width: 500px;
  }
  .banner-left-cnt h1 {
      font-size: 54px;
      line-height: 1.1;
      color: #fff;
      font-weight: 700;
      margin: 0 0 20px;
  }
  .banner-left-cnt p {
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      margin: 0;
  }
  .banner-left-cnt .banner-cnt {
      margin-bottom: 35px;
  }
  .banner-left-cnt .banner-contact p {
      margin: 0 0 8px;
  }
  .banner-left-cnt .banner-contact p a.lp-bann-num {
      font-size: 33px;
      line-height: 45px;
      color: #ffffff;
      font-weight: 500;
  }
  .banner-left-cnt .banner-contact p a.lp-bann-mail {
      font-size: 22px;
      font-weight: 500;
      color: #ffff;
  }
  .banner-left-cnt .banner-contact p a:hover{
      color: #000;
  }

  /*==== Divorce Content ====*/

  .lp-divorce-cnt {
      padding: 75px 0 50px;
      text-align: center;
  }
  .lp-divorce-cnt .page-content h3 {
      font-size: 31px;
      line-height: 44px;
      color: #333a45;
      font-weight: bold;
      text-align: center;
      margin: 0 0 10px;
  }
  .lp-divorce-cnt .page-content p {
      margin: 0 0 25px;
  }

  /*==== Faq Section ====*/

  .ct-acc-cnt {
      max-width: 620px;
      margin: 0 auto;
      position: relative;
      padding-right: 75px;
  }

  .ct-acc-cnt span.accordion__icon {
      position: absolute;
      right: 0;
  }

  /*===== Testimonial Section Css =====*/

  .testimonial_section_wrap{
      background-color: #333945;
      width: 100%;
      padding: 85px 20px;
      color: #fff;
      text-align: center;
  }
  .testimonial_section_wrap h2.heading_custom {
      font-size: 31px;
      line-height: 1.3;
      color: #ffffff;
      font-weight: bold;
      text-align: center;
      margin: 0 0 30px;
  }
  .testimonial_section_wrap .top_qimg,.testimonial_section_wrap .bottom_qimg {
      margin-bottom: 20px;
      display: inline-block;
      max-width: 20px;

      img {
          width: 100%;
      }
  }
  .testimonial_section_wrap .ct_lawyer_wrap {
      font-size: 18px;
      line-height: 27px;
      color: #52bb63;
      margin-bottom: 35px;
  }
  .testimonial_section_wrap .testimonials_post_content {
      padding-left: 150px;
      padding-right: 150px;
      margin-bottom: 30px;
  }
  .testimonial_section_wrap .testimonials_post_inner {
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
  }
  .testimonial_section_wrap .testimonials_post_content p {
      margin: 0;
  }
  .testimonial_section_wrap
   .testimonials_post_title {
      margin-bottom: 25px;
  }
  .testimonial_section_wrap .slick-next::before {
      content: "\f105";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 50px;
      color: #fff;
  }
  .testimonial_section_wrap .slick-prev::before {
      content: "\f104";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 50px;
      color: #fff;
  }

  /*=== Dots Css ===*/

  .testimonial_section_wrap ul.slick-dots li button:before{
      opacity: 1
  }
  .testimonial_section_wrap ul.slick-dots li{
     margin: 0 1px;
  }
  .testimonial_section_wrap ul.slick-dots li button:before{
      font-size: 50px;
      color: #fff;
  }
  .testimonial_section_wrap .slick-dots li.slick-active button:before{
     color: #51bb63;
  }

  /*=== Middle Contact Css ===*/

  .lp-middle-contact h3 {
      font-size: 31px;
      line-height: 44px;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
  }
  .lp-middle-contact p {
      text-align: center;
      margin: 0;
      font-size: 24px;
      line-height: 44px;
      color: #ffffff;
      font-weight: bold;
  }
  .lp-middle-contact p a {
      color: #ffffff;
  }
  .lp-middle-contact p a:hover{
      color: #000;
  }
  .lp-middle-contact {
      background-color: #51bb63;
      padding: 65px 0;
  }

  /*=== Video Section Css ===*/

  .lp-video-wrapper {
      padding: 60px 0  145px;
  }
  .lp-video-wrapper .vid-col{
     padding-left: 10px;
     padding-right: 10px;
     flex: 0 0 50%;
  }
  .lp-vid-box .lp-video-sec {
      width: 100%;
      height: 400px;
      position: relative;
  }
  .lp-vid-box .lp-video-sec iframe.i-video {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
  }
  .lp-vid-box .lp-video-sec .vid-img {
      background-size: cover;
      background-position: center center;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
  }
  .lp-vid-box .vid-title {
      text-align: center;
      padding: 20px;
      background: #333945;
      color: #fff;
  }
  .lp-vid-box .vid-title h4 {
      font-size: 31px;
      line-height: 44px;
      color: #ffffff;
      font-weight: bold;
      margin: 0 0 10px;
  }
  .lp-vid-box .vid-title a.ct-cmn-btn {
      background: #51bb63;
  }
  .lp-vid-box .vid-title a.ct-cmn-btn:hover {
      background: #fff;
      color: #51bb63;
  }



  /*==== Media Css ====*/

  @media only screen and (max-width: 1920px) {



  }

  @media only screen and (max-width: 1680px) {
    .lp-banner-area{
      height: auto;
    }
    .ctmain-form span.wpcf7-not-valid-tip {
      position: relative;
      bottom: 5px;
      left: 0;
      font-size: 13px;
    }
    .ctmain-form .wpcf7 form.invalid .wpcf7-response-output,
    .ctmain-form .wpcf7 form.unaccepted .wpcf7-response-output{
      display: block !important;
      margin: 0;
    }
  }

  @media only screen and (max-width: 1480px) {

    .lp-vid-box .lp-video-sec .vid-img a.vid-playbtn img.ctplay-icon {
        max-width: 70px;
    }


  }

  @media only screen and (max-width: 1280px) {


    .banner-left-cnt .banner-contact p a.lp-bann-num{
      font-size: 28px;
    }
    .banner-left-cnt h1{
        font-size: 45px;
    }
    .lp-middle-contact h3,
    .lp-divorce-cnt .page-content h3,
    .testimonial_section_wrap h2.heading_custom,
    .lp-vid-box .vid-title h4{
        font-size: 25px;
        line-height: 1.4;
    }
    .ct-acc-cnt{
      max-width: 100%;
      width: 100%;
      padding-right: 40px;
    }
    h3.accordion__title {
      font-size: 16px;
  }
    .ct-lp-connum a{
      font-size: 28px;
    }

    .lp-vid-box .lp-video-sec{
        height: 350px;
      }


  }


  @media only screen and (max-width: 991px) {


    .banner-left-cnt h1{
      font-size: 35px;
    }

    /*=== Banner Css  ===*/

    .ct-banner-sec .ct-banner-inner{
        display: block;
    }
    .ct-banner-sec .ct-banner-inner .bann-col{
        display: block;
        width: 100% !important;
    }
    .banner-left-cnt{
      max-width: 100%;
      text-align: center;
      margin-bottom: 25px;
    }
    .banner-form .banner-wrapper{
      max-width: 100%;
    }
    .testimonial_section_wrap{
      padding: 50px 25px;
    }

    .testimonial_section_wrap .testimonials_post_content{
        padding-left: 0;
        padding-right: 0;
    }



  }
  @media only screen and (max-width: 767px) {


      .lp-vid-box .lp-video-sec{
        height: 250px;
      }
      .lp-video-wrapper,
      .lp-divorce-cnt{
        padding: 30px 0;
      }
      .ccustom-header{
        text-align: center;
      }
      .half-col{
        float: none;
        width: 100%;
      }
      .ct-lp-connum {
          text-align: center;
          padding: 15px 0;
      }
      .lp-banner-area{
        padding-top: 250px;
      }
      .lp-video-wrapper .vid-col {
          width: 100%;
          display: block;
          flex: none;
          margin-bottom: 35px;
      }
      .lp-video-wrapper .row {
          display: block;
      }
      .testimonial_section_wrap .testimonials_post_content p{
        font-size: 16px;
      }
      .lp-middle-contact p{
        font-size: 19px;
        line-height: 1.5;
      }
      .lp-middle-contact p span{
          display: block;
      }
      .lp-vid-box .lp-video-sec .vid-img a.vid-playbtn img.ctplay-icon{
        max-width: 45px;
      }
      .accordion__item {
          padding: 20px 0;
      }

      h3.accordion__title {
          margin: 0;
      }
  }


.template--landing-page {

    .accordion {
        width: 100%;
        margin: 3em 0; }
        .accordion--active .accordion__icon {
          transform: rotate(180deg); }
        .accordion__item {
          border-top: 1px solid #d6d8da;
          padding: 2em 0 0 0;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
          justify-content: center; }
        .accordion__title {
          color: #333a45;
          cursor: pointer;
          margin-bottom: 2em;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -ms-flex-align: center;
          align-items: center; }
        .accordion__icon {
          transition: transform 0.26s ease; }
          .accordion__icon .cls-1 {
            fill: none;
            stroke: #333a45;
            stroke-linecap: round;
            stroke-linejoin: round; }
        .accordion__content {
          display: none;
          margin-bottom: 2em; }
          .accordion__content--active {
            display: block; }
          .accordion__content ul {
            list-style: disc; }
          .accordion__content ol {
            list-style: decimal; }
          .accordion__content ul, .accordion__content ol {
            list-style-position: inside;
            margin-bottom: 1.5em; }
          .accordion__content table {
            margin-bottom: 1.5em; }
          .accordion__content table, .accordion__content td {
            border: 1px solid #d6d8da; }
          .accordion__content table, .accordion__content th {
            font-weight: bold; }
          .accordion__content td {
            padding: 4px; }
          .accordion__content small {
            font-size: 0.888em; }

}
