.cda-register-modal {
    @include absoluteFit();
    position: fixed;
    z-index: 101;
    overflow: hidden;

    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;

    &.modal-open {
        opacity: 1;
        visibility: visible;

        .cda-register-modal__inner {
            transform: translateY(-50%) scale(1);
        }

    }

}

.cda-register-modal__bg {
    @include absoluteFit();
    background-color: rgba($color--panel-dark, 0.8);
    cursor: pointer;
}

.cda-register-modal__inner {
    @include absoluteFit();
    @include wrap();
    position: relative;
    top: 50%;
    width: 90%;
    max-width: 630px;
    max-height: 600px;
    padding-top: 50px;
    overflow: auto;
    background-color: rgba($color--panel, 0.95);
    backdrop-filter: blur(15px);
    border-radius: 42px;
    border-top-left-radius: 0px;

    transform: translateY(-48%) scale(0.95);
    transition: transform 0.25s ease-in-out;

}

.editor-content .cda-register-modal__heading {
    margin-top: 0px;
    margin-bottom: 20px;
}

.cda-register-modal__form {
    @include wrap();
    max-width: 550px;
}

.cda-register-modal__form .wpcf7 {

    .input-container {

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &:last-child {
            margin-top: 20px;
        }

    }

    .input-container label {
        font-size: 15px;
        font-weight: $font-weight--medium;
        color: $color--text;
        display: block;
        margin-bottom: 5px;
        margin-top: 0px;
    }

    .input-container input, .input-container select {
        width: 100%;
        max-width: none;
        min-width: none;
        appearance: none;
        background-color: $color--panel;
        border: none;
        outline: none;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 14px;
        font-weight: $font-weight--medium;
        color: $color--text;
        border-radius: 4px;
        border: 1px solid $color--panel-border;
    }

    .input-container input[type="submit"] {
        background-color: $color--panel-dark;
        color: $color--text-light;
        font-weight: bold;
        font-size: 20px;
        padding-top: 10px;
        padding-bottom: 11px;
    }

    .wpcf7-not-valid-tip {
        font-size: 14px;
        font-weight: $font-weight--medium;
        display: block;
        margin-top: 5px;
        position: absolute;
        top: -4px;
        right: 9px;
    }

    .wpcf7-response-output {
        padding: 0px;
        margin: 0px;
        border: none;
        font-weight: $font-weight--medium;
        font-size: 15px;
        color: $color--text;
    }

}
