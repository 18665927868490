.service-card {
    display: block;
    position: relative;
    width: 100%;
    background-color: $color--panel;
    border-radius: 25px;
    border-top-left-radius: 0px;
    min-height: 300px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border: 1px solid $color--panel-border;

    &:hover {
        transform: scale(1.02);
    }
}

.service-card__bg {
    @include absoluteFit();
    position: absolute !important;
    @include bgImage();
}

.service-card__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $color--panel;
}

.service-card__heading {
    display: block;
    margin: 0;
    font-size: 20px !important;
    font-weight: $font-weight--bold;
    color: $color--heading !important;
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    min-height: 60px;
    text-decoration: none;

    br {
        display: none;
    }

    &:after {
        display: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.service-card__cta {}
