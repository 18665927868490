body.settings-layout {
    margin: 0px;
    background-color: #f3f4f6;

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        font-family: Arial, Helvetica, sans-serif;
    }

    #navigation {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

        #logo {
            padding: 5px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            img {
                width: 95%;
                max-width: 200px;
            }
        }

        #links {
            padding: 5px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            gap: 5px;
            flex-wrap: wrap;

            a {
                padding: 10px;
                color: #000;
                text-decoration: none;
                border-radius: 10px;
                background-color: #f4f4f4;

                &:hover {
                    background-color: #56b464;
                    color: #fff;
                }
            }
        }
    }

    #content_wrapper {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        #content {
            width: 100%;
        }
    }
}