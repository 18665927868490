.team-member-card {
    position: relative;
    background-color: $color--panel-contrast;
    border-radius: 20px;
    border-top-left-radius: 0px;
    overflow: hidden;
    border: 1px solid $color--panel-border-2;
    display: flex;
    flex-direction: column;

    box-shadow: $shadow--panel-down;
    transition: box-shadow 0.4s ease-in-out;

    &:hover {
        box-shadow: $shadow--panel-down-hovered;
    }
}

.team-member-card__photo {
    width: 100%;
    height: 260px;

    // Not official shade, whoever made the images got it wrong
    background-color: #49AB59;

    .responsive-image__img {
        position: absolute;
        width: 250px;
        height: 250px;
        object-fit: contain;
        top: initial;
        background-position: right;
        bottom: 0;
        right: 0;
        left: initial;
        transform: initial;
    }

}

.team-member-card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
}

.team-member-card__inner {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    &:last-of-type {
        border-top: 1px solid $color--panel-border;
    }
}

.team-member-card__name {
    display: block;
    font-size: 22px;
    font-weight: $font-weight--bold;
    color: $color--heading;
    margin-bottom: 5px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.team-member-card__position {
    display: block;
    font-size: 16px;
    font-weight: $font-weight--medium;
    color: $color--heading-brand;
    margin-bottom: 15px;
}

.team-member-card__excerpt {
    display: block;
    font-size: 15px;
    line-height: 23px;
    font-weight: $font-weight--medium;
    color: $color--text-tertiary;
    margin-bottom: 10px;
}

.team-member-card__link {
    display: inline-block;
    font-size: 15px;
    font-weight: $font-weight--medium;
    color: $color--text;
    position: relative;
    text-decoration: none !important;

    &:after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: -5px;
        left: 0;
        right: 0;
        width: 98%;
        margin: auto;
        background-color: $color--brand-primary;
    }
}

.team-member-card__contact {
    font-size: 15px;
    font-weight: $font-weight--medium;
    color: $color--text;

    span {
        display: inline-block;
        width: 50px;
    }

    a {
        color: inherit;
        text-decoration: none !important;
        position: relative;
    }

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }

}
