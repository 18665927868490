.request-callback-modal {

    .landing-hero-form-modal__success {
        display: none;
    }

}

.request-callback-modal--success {

    .landing-hero-form-modal__success {
        display: flex;
        border-bottom: none;
    }

    .landing-hero-form-modal__error,
    .landing-hero-form-modal__heading,
    .landing-hero-form-modal__form {
        display: none;
    }

}

.request-callback-modal--error {}
