@import '~noty/src/noty';
// @import '~noty/src/themes/relax';
@import '~noty/src/themes/semanticui';


.noty_bar {
    animation-duration: 0.1s;
    text-align: center;
}

.noty_bar .noty_body {
    padding: 15px 25px;
    font-size: 16px;
    text-align: left;
    user-select: none;
}

#noty_layout__topRight {
    top: 100px;
}
