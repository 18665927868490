.news-card {
    position: relative;
    width: 100%;
    height: 40vw;
    min-height: 220px;
    max-height: 400px;
    border-radius: 30px;
    overflow: hidden;
    border: 2px solid $color--brand-primary;

    &:hover {
        .news-card__content {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}

.news-card--content-hidden {

    .news-card__content {
        transform: translateY(30px);
        opacity: 0;
        visibility: hidden;
    }

}

.news-card__img {
    @include bgImage();
    width: 100%;
    height: 100%;
}

.news-card__fixed-heading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color--panel;
    padding: 25px;
}

.news-card__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color--panel;
    padding: 25px;
    z-index: 1;

    transition: all 0.2s ease;
}

.news-card__heading {
    font-size: 22px;
    font-weight: $font-weight--medium;
    color: $color--heading;
    margin-bottom: 10px;
}

.news-card__excerpt {
    font-size: 15px;
    font-weight: $font-weight--regular;
    color: $color--text;
    margin-bottom: 30px;
    max-height: 95px;
    overflow: hidden;
}

.news-card__link {}
