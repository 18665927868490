.menu-item-card {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #e6e8e5;
    border-radius: 12px;
    border-top-left-radius: 0px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
    height: 100%;

    img {
        width: 45px;
        margin-right: 15px;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        line-height: 1.45;
        font-weight: $font-weight--bold;
        color: $color--heading;

        &:hover {
            color: $color--brand-primary;
        }
    }

    span {
        display: block;
        font-size: 14px;
        line-height: 1.45;
        font-weight: $font-weight--regular;
        color: $color--text;
    }


    &:hover {
        border: 1px solid $color--panel-border;
        // box-shadow: 0px 15px 37px -32px rgba(0, 0, 0, 0.3);

        // a {
        //     color: $color--brand-primary;
        // }

    }


    &.menu-item-card--active {
        a {
            color: $color--heading-brand;
        }
    }

}
