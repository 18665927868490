body.template--contact {


    .contact-hero {
        padding-top: 70px;
        padding-bottom: 60px;
    }

    .contact-hero__inner {
        @include wrap();
        display: flex;
        justify-content: space-between;
    }

    .contact-hero__content-left {
        margin-right: 40px;
        max-width: 655px;
    }

    .contact-hero__content-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .contact-hero__heading {
        font-size: 44px;
        font-weight: $font-weight--bold;
        color: $color--heading;
        margin-bottom: 15px;
    }

    .contact-hero__copy {
        font-size: 18px;
        font-weight: $font-weight--medium;
        line-height: 1.5;
        color: $color--text-tertiary;
        margin-bottom: 25px;
    }

    .contact-hero__opening-times {
        font-size: 22px;
        font-weight: $font-weight--medium;
        line-height: 1.5;
        color: $color--text;
    }

    .contact-hero__team-circles {
        width: 335px;
        height: 99px;
        margin-bottom: 10px;
    }

    .contact-hero__review-text {}


    @media screen and (max-width: 992px) {

        .contact-hero {
            padding-top: 50px;
        }

        .contact-hero__content-left {
            max-width: 415px;
        }

        .contact-hero__content-right {}

        .contact-hero__heading {
            font-size: 28px;
            margin-bottom: 15px;
        }

        .contact-hero__copy {
            font-size: 17px;
            margin-bottom: 25px;
        }

        .contact-hero__opening-times {
            font-size: 19px;
        }

        .contact-hero__team-circles {
            width: 335px;
            height: 99px;
            margin-bottom: 10px;
        }

        .contact-hero__review-text {
            display: none;
        }

    }


    @media screen and (max-width: 850px) {

        .contact-hero__inner {
            align-items: flex-start;
        }

        .contact-hero__team-circles {
            width: 245px;
            height: 73px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

    }


    @media screen and (max-width: 768px) {

        .contact-hero__team-circles {
            width: 163px;
            height: 49px;
            margin-top: 0px;
        }

    }


    @media screen and (max-width: 650px) {

        .contact-hero {
            padding-top: 40px;
        }

        .contact-hero__team-circles {
            width: 163px;
            height: 49px;
            margin-top: 0px;
        }

        .contact-hero__inner {
            position: relative;
        }

        .contact-hero__content-left {
            max-width: initial;
            margin-right: 0px;
        }

        .contact-hero__content-right {
            position: absolute;
            right: 15px;
        }

        .contact-hero__heading {
            font-size: 31px;
            margin-bottom: 28px;
        }

        .contact-hero__copy {
            max-width: initial;
        }

        .contact-hero__team-circles {
            width: 143px;
            height: 43px;
        }


    }


    @media screen and (max-width: 430px) {

        .contact-hero__heading {
            font-size: 31px;
            margin-bottom: 28px;
        }

        .contact-hero__copy {
            font-size: 16px;
        }

        .contact-hero__opening-times {
            font-size: 19px;
            max-width: initial;
            line-height: 1.4;
        }

    }





    .contact-grid {
        padding-bottom: 75px;
    }

    .contact-grid__inner {
        @include wrap();
        display: flex;
    }

    .contact-grid__locations {
        flex-basis: calc(66.666666% - 10px);
        margin-right: 20px;
    }

    .contact-grid__other {
        flex-basis: calc(33.333333% - 10px);
    }


    .contact-grid__locations-slider {
        // width: 100%
        overflow: visible;

        .swiper-wrapper {}

        .swiper-slide {}

    }


    .contact-grid__locations-slider-pagination {
        display: none;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 40px;

        .swiper-pagination-bullet {
            width: 13px;
            height: 13px;
            background-color: $color--panel-border-2;
            border-radius: 50%;

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.swiper-pagination-bullet-active {
                background-color: $color--aticus-blackish;
            }

        }
    }

    .contact-grid__location {}



    .contact-grid__live-chat {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 60px;
        padding-bottom: 42px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .contact-grid__live-chat-icon {
        width: 42px;
        height: 42px;
        margin-bottom: 12px;
    }

    .contact-grid__live-chat-heading {
        font-weight: $font-weight--bold;
        font-size: 18px;
        color: $color--heading;
        text-align: center;
        margin-bottom: 24px;
    }

    .contact-grid__live-chat-button {}



    .contact-grid__whatsapp {
        padding-top: 30px;
        padding-bottom: 35px;
    }

    .contact-grid__whatsapp-icon {
        display: block;
        margin: auto;
        margin-bottom: 25px;
        width: 45px;
        height: 45px;
    }

    .contact-grid__whatsapp-heading {
        font-weight: $font-weight--bold;
        font-size: 18px;
        color: $color--heading;
        text-align: center;
        margin-bottom: 7px;
    }

    .contact-grid__whatsapp-status {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }

    .contact-grid__whatsapp-status-indicator {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 7px;
        background-color: $color--panel-contrast;
    }

    .contact-grid__whatsapp-status-text {}

    .contact-grid__whatsapp-status-text-open {
        font-weight: $font-weight--medium;
        font-size: 12px;
        color: $color--text-tertiary;
        display: none;
    }

    .contact-grid__whatsapp-status-text-closed {
        font-weight: $font-weight--medium;
        font-size: 12px;
        color: $color--text-tertiary;
        display: none;
    }

    .contact-grid__whatsapp-ctas {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .contact-grid__whatsapp-button {
        justify-self: flex-start;
    }

    .contact-grid__whatsapp-ctas-sep {
        height: 50px;
        width: 2px;
        background-color: $color--panel-border-2;
        margin-left: 25px;
        margin-right: 25px;
        justify-self: center;
    }

    .contact-grid__whatsapp-ctas-qr {
        justify-self: flex-end;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        span {
            margin-top: 5px;
            font-weight: $font-weight--bold;
            font-size: 15px;
            color: $color--heading;
            display: block;
        }
    }




    @media screen and (min-width: 751px) {

        .contact-grid__locations-slider {

            .swiper-slide {
                width: calc(50% - 10px) !important;
            }

        }

    }


    @media screen and (max-width: 1050px) {

        .contact-grid__inner {
            flex-wrap: wrap;
        }

        .contact-grid__locations {
            flex-basis: 100%;
            margin-right: 0px;
            margin-bottom: 20px;
        }

        .contact-grid__other {
            flex-basis: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .contact-grid__form {
            flex-basis: calc(50% - 10px);

        }

        .contact-grid__live-chat {
            flex-basis: calc(50% - 10px);
        }

    }

    @media screen and (max-width: 750px) {

        .contact-grid__locations-slider-pagination {
            display: flex;
        }

        .contact-grid {
            padding-bottom: 30px;
        }

        .contact-grid__inner {
            padding: 0px !important;
        }

        .contact-grid__locations {
            padding-bottom: 20px;
            margin-bottom: 0px;
        }

        .contact-grid__locations-slider {
            max-width: none;
            width: 90vw !important;
            overflow: visible !important;
        }

        .contact-grid__location {
            .location-card__map {
                display: none;
            }
        }

        .contact-grid__other {
            display: block;
            padding: 0px;
        }

        .contact-grid__form {

            .request-callback-card-form {
                border-radius: 0px;
                border-left: none;
                border-right: none;
                background-color: transparent;
            }
        }


        .contact-grid__whatsapp {
            border-top: 1px solid $color--panel-border-2;
            padding-top: 50px;
            padding-bottom: 40px;
        }

        .contact-grid__inner {
            display: block;
        }

        // ere

    }


}


body[data-business-open="true"] {

    .contact-grid__whatsapp-status-indicator {
        background-color: $color--panel-brand;
    }

    .contact-grid__whatsapp-status-text-open {
        display: block;
    }

}

body[data-business-open="false"] {

    .contact-grid__whatsapp-status-indicator {
        background-color: red;
    }

    .contact-grid__whatsapp-status-text-closed {
        display: block;
    }

}
