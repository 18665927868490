.consultation-cta-block {
    border-top: 1px solid $color--panel-border-2;
}

.consultation-cta-block__inner {
    @include wrap();
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
}

.consultation-cta-block__heading {
    font-size: 20px;
    font-weight: $font-weight--medium;
    color: $color--heading;
    flex: 1;
    margin-right: 40px;
}

.consultation-cta-block__cta {}



@media screen and (max-width: 768px) {

    .consultation-cta-block__inner {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .consultation-cta-block__heading {
        font-size: 20px;
        margin-right: 0px;
        text-align: center;
        margin-bottom: 20px;
    }

}
