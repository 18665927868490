.our-team-slider-block {
    padding-top: 50px;
    padding-bottom: 30px;
    border-top: 1px solid $color--panel-border-2;
}

.our-team-slider-block__inner {
    @include wrap();
    position: relative;
}

.our-team-slider-block__heading {
    font-size: 32px;
    font-weight: $font-weight--bold;
    color: $color--heading;
    text-align: center;
    margin-bottom: 25px;
}

.our-team-slider-block__slider {
    position: relative;
    z-index: 1;

    .swiper-wrapper {
        height: fit-content !important;
        padding-top: 15px;
        padding-bottom: 20px;
    }

    .swiper-slide {
        height: auto;
    }

    .team-member-card {
        height: 100%;
    }

}


.our-team-slider-block__slider-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.our-team-slider-block__slider-nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: $color--panel-brand;
    cursor: pointer;
    user-select: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;

    &:hover {
        background-color: lighten($color--panel-brand, 3%);
        opacity: 0.8;
    }

    &:active {
        background-color: lighten($color--panel-brand, 5%);
        opacity: 1;
    }

    img {
        width: 60%;
    }

}

.our-team-slider-block__slider-nav-btn--prev {
    margin-right: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    left: -50px;
}

.our-team-slider-block__slider-nav-btn--next {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    right: -50px;
}



@media screen and (max-width: 1440px) {

    .our-team-slider-block__slider {
        display: block;
        max-width: 75vw;
        margin: auto;
    }

    .our-team-slider-block__slider-nav-btn--prev {
        left: 30px;
    }

    .our-team-slider-block__slider-nav-btn--next {
        right: 30px;
    }

}

@media screen and (max-width: 1340px) {

    .our-team-slider-block__slider {
        max-width: 80vw;
    }

}

@media screen and (max-width: 950px) {

    .our-team-slider-block {
        padding-bottom: 50px;
    }

    .our-team-slider-block__inner {
        padding: 0px !important;
    }

    .our-team-slider-block__slider {
        max-width: none;
        width: 90vw !important;
        overflow: visible !important;
    }

    .our-team-slider-block__slider-nav-btn {
        position: relative;
        top: 0;
        transform: translateY(0);
        right: 0;
        left: 0;
    }

}


@media screen and (max-width: 768px) {

    .our-team-slider-block__heading {
        font-size: 26px;
    }

}
