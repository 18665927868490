.site-footer {}

.site-footer__menus {
    background-color: $color--panel;
    border-top: 1px solid $color--panel-border-2;
    padding-top: 30px;
    padding-bottom: 55px;
}

.site-footer__menus-inner {
    @include wrap();
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.site-footer__menu-group {
    min-width: 200px;
    max-width: 280px;
}

.site-footer__menu-sep {
    margin-top: 0px;
    margin-bottom: 30px;
    height: 2px;
    width: 100%;
    background-color: $color--panel-border-2;
    display: block;
    padding: 0px;
    border: none;
    outline: none;
}

.site-footer__menu-heading {
    font-weight: $font-weight--medium;
    font-size: 17px;
    color: $color--heading;
    line-height: 20px;
    margin-bottom: 22px;
}

.site-footer__menu-logo {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 7px;

    img {
        width: 116px;
        height: 30px;
        display: block;
    }

}

.site-footer__menu-nav {

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
    }

    li {
        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }

}

.site-footer__menu-nav-link {
    display: inline-block;
    font-weight: $font-weight--medium;
    font-size: 15px;
    color: $color--text-tertiary;
    line-height: 20px;
    text-decoration: none;

    appearance: none;
    background-color: transparent;
    border: none;
    font-family: inherit;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.site-footer__email,
.site-footer__phone {
    display: flex;
    align-items: center;

    img {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        opacity: 0.4;
    }

    a {
        font-size: 20px;
        font-weight: $font-weight--medium;
        color: $color--text;
        text-decoration: none;
    }

    &:hover {
        a {
            text-decoration: underline;
        }
    }
}


.site-footer__phone {
    margin-bottom: 15px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 17px;
        margin-left: 2px;
    }
}

.site-footer__email {
    margin-bottom: 25px;

    img {
        width: 26px;
        height: 26px;
        margin-right: 13px;
    }
}


.site-footer__legal {
    background-color: $color--panel-contrast;
    border-top: 1px solid $color--panel-border-2;
    padding-top: 30px;
    padding-bottom: 27px;
}


.site-footer__legal-inner {
    @include wrap();
    display: flex;
    align-items: flex-start;
}

.site-footer__legal-content {
    flex: 1;
}

.site-footer__copyright {
    font-size: 14px;
    font-weight: $font-weight--medium;
    color: $color--text-secondary;
    line-height: 22px;
    margin-bottom: 11px;
}

.site-footer__legal-copy {
    font-size: 14px;
    font-weight: $font-weight--medium;
    color: $color--text-secondary;
    line-height: 22px;
    margin-bottom: 18px;
}

.site-footer__legal-nav {

    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0;
        display: flex;
        align-items: center;
    }

    li {
        &:not(:last-child) {
            margin-right: 25px;
        }
    }

}

.site-footer__legal-nav-link {
    font-size: 14px;
    font-weight: $font-weight--medium;
    color: $color--text-tertiary;
    display: block;
}

.site-footer__SA-logo {
    flex-basis: 165px;
    height: 97px;
    margin-left: 5vw;
    margin-top: 22px;
    overflow: hidden;
}

.site-footer__SA-logo-iframe {
    width: 100%;
    display: block;
}


@media screen and (max-width: 1100px) {

    .site-footer__menus-inner {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .site-footer__menu-group {
        width: 270px;
        max-width: initial;
        min-width: initial;

        &:not(:last-child) {
            margin-bottom: 60px;
        }

        &:not(:nth-child(even)) {
            margin-right: 10vw;
        }

    }

}


@media screen and (max-width: 900px) {

    .site-footer__legal-nav ul {
        flex-wrap: wrap;
        max-width: 300px;

        li {
            margin-bottom: 5px;
        }

    }

}


@media screen and (max-width: 700px) {

    .site-footer__menus-inner {
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .site-footer__menu-group {
        width: 100%;

        &:not(:nth-child(even)) {
            margin-right: 0;
        }

    }

    .site-footer__legal-inner {
        flex-direction: column-reverse;
    }

    .site-footer__legal-nav ul {
        max-width: initial;
    }

    .site-footer__SA-logo {
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 20px;
    }

}
