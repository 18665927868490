body.settings-campaign_landing_pages {
    margin: 0px;

    [data-href] {
        cursor: pointer;
    }

    .text-center {
      text-align: center;
    }

    .section {
      padding: 10px;
      background-color: #fff;
      margin-bottom: 10px;

      &.hover[data-href]:hover {
        background-color: #56b464;
        color: #fff;
      }

      #campaign_landing_pages {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;

        td, th {
          border: 1px solid #ddd;
          padding: 8px;
        }

        tr:nth-child(even){
          background-color: #f2f2f2;
        }

        tr:hover:not(.hover_off) {
          background-color: #ddd;
        }

        th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #56b464;
          color: #fff;
        }

        .no_records {
          text-align: center;
        }
      }
    }
}