body.template--location {

    .opening-times-block {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid $color--panel-border;
    }

    .opening-times-block__inner {
        @include wrap();
    }

    .opening-times-block__text {
        font-size: 25px;
        font-weight: $font-weight--medium;
        color: $color--heading;
        text-align: center;
    }


    .locations-block {
        margin-bottom: 50px;
    }

    .locations-block__inner {
        @include wrap();
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .locations-block__heading {
        font-size: 32px;
        font-weight: $font-weight--bold;
        color: $color--heading-brand;
        margin-bottom: 20px;
        flex-basis: 100%;
    }

    .locations-block__content {
        flex: 1;
        margin-right: 40px;
    }

    .location-card {
        margin-bottom: 30px;
        flex-basis: 400px;
        height: auto;
    }


    @media screen and (max-width: 992px) {

        .location-card {
            flex-basis: 350px;
        }

    }

    @media screen and (max-width: 768px) {

        .locations-block__content {
            margin-right: 0px;
            margin-bottom: 30px;
            flex-basis: 100%;
        }

        .opening-times-block__text {
            font-size: 20px;
        }

        .locations-block__heading {
            font-size: 28px;
        }

    }


}
