.single-review-block {
    margin-top: 50px;
    margin-bottom: 50px;
}

.single-review-block__inner {
    @include wrap();
    display: flex;
}

.single-review-block__content {
    flex-basis: 45%;
    padding-right: 30px;
    padding-bottom: 40px;
}

.single-review-block__heading {
    font-size: 35px;
    font-weight: $font-weight--medium;
    line-height: 1.2;
    color: $color--heading;
    margin-top: 10px;
    margin-bottom: 30px;
}

.single-review-block__copy {
    font-size: 18px;
    font-weight: $font-weight--regular;
    line-height: 1.4;
    margin-bottom: 20px;
    color: $color--text;
}

.single-review-block__quote {
    font-size: 18px;
    font-weight: $font-weight--medium;
    line-height: 1.4;
    margin-bottom: 30px;
    color: $color--text;

    span {
        font-weight: $font-weight--medium;
        font-size: 24px;
    }
}

.single-review-block__cta {}

.single-review-block__img {
    @include bgImage();
    flex-basis: 55%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}


@media screen and (max-width: 992px) {

    .single-review-block {
        position: relative;
    }

    .single-review-block__img {
        position: absolute;
        left: 30px;
        right: 30px;
        top: 0;
        bottom: 0;
    }

    .single-review-block__content {
        flex-basis: 85%;
        z-index: 1;
        position: relative;
        background-color: rgba($color--panel, 0.9);
        backdrop-filter: blur(9px);
        border-top-right-radius: 20px;
        padding-left: 30px;
        padding-top: 30px;
        margin-top: 80px;
    }

    .single-review-block__heading {
        font-size: 30px;
        margin-bottom: 20px;
    }

}


@media screen and (max-width: 450px) {

    .single-review-block__inner {
        padding-left: 0px;
        padding-right: 0px;
    }

    .single-review-block__content {
        flex-basis: 95%;
    }

    .single-review-block__img {
        left: 0;
        right: 0;
    }

}
