.pagination {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
}

.pagination__inner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination__prev,
.pagination__next {
    padding: 8px;
    border: 1px solid $color--panel-border;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
        border: 1px solid $color--panel-border;

        img {
            opacity: 0.3;
        }
    }

    &:not(.disabled) {
        &:hover, &:active {
            border: 1px solid $color--brand-primary;
        }
    }

    img {
        width: 15px;
        height: 15px;
        opacity: 0.8;
    }
}

.pagination__prev {
    &.disabled {}
}

.pagination__next {
    &.disabled {}
}

.pagination__links {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
}

.pagination__link {

    &:not(:last-child) {
        margin-right: 25px;
    }
}

.pagination__number {
    font-size: 20px;
    font-weight: $font-weight--medium;
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        margin: auto;
        height: 2px;
        width: 100%;
        background-color: $color--heading;
        opacity: none;
    }

    &.active, &:hover {
        color: $color--heading;
        position: relative;

        &:after {
            opacity: 1;
        }
    }
}
