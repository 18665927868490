.request-callback-card-form {
    background-color: $color--panel;
    border: 1px solid $color--panel-border-2;
    // box-shadow: $shadow--panel-down;
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 26px;
    padding-right: 26px;

    .landing-hero-contact-form__success {
        color: $color--text-tertiary;
        text-align: center;
    }

}

.request-callback-card-form__heading {
    font-size: 18px;
    color: $color--text-tertiary;
    font-weight: $font-weight--bold;
    text-align: center;
    margin-bottom: 23px;
    text-align: center;
    display: block;
}

.request-callback-card-form__input {
    @extend %inputWhite;
    border: 1px solid $color--input-border;
    margin-bottom: 17px;
    width: 100%;
}

.request-callback-card-form__submit {
    width: 100%;
    border-radius: 6px;
}


@media screen and (max-width: 430px) {

    .request-callback-card-form {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

}
