.resources-block {
    padding-bottom: 50px;
}

.resources-block__inner {
    @include wrap();
    display: flex;
    flex-wrap: wrap;
}

.resources-block__card {
    margin-bottom: 30px;
    flex-basis: calc(33.333333% - 20px);
    align-self: stretch;

    &:not(:nth-child(3n)) {
        margin-right: 30px;
    }

    transition: all 0.15s ease;

    &:hover {
        transform: scale(1.01);
        box-shadow: 0px 16px 41px -27px rgba(#000, 0.3);
    }


}

@media screen and (max-width: 992px) {

    .resources-block__card {
        flex-basis: calc(50% - 15px);

        &:not(:nth-child(3n)) {
            margin-right: 0px;
        }

        &:not(:nth-child(2n)) {
            margin-right: 30px;
        }

    }

}


@media screen and (max-width: 650px) {

    .resources-block__card {
        flex-basis: 100%;

        &:not(:nth-child(2n)) {
            margin-right: 0px;
        }

    }

}



.resource-card {
    position: relative;
    border: 1px solid $color--panel-border;
    border-radius: 18px;
    border-top-right-radius: 0px;
    overflow: hidden;
    height: 100%;
}

.resource-card__image {
    width: 100%;
    height: 35vh;
    min-height: 180px;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120%;
        transform: translateX(-50%) translateY(-50%);
    }

}

.resource-card__content {
    padding-top: 25px;
    padding-bottom: 65px;
    padding-left: 25px;
    padding-right: 25px;
}

.resource-card__title {
    font-size: 25px;
    font-weight: $font-weight--bold;
    color: $color--heading;
    margin-bottom: 15px;
}

.resource-card__btns {
    position: absolute;
    bottom: 25px;
    left: 25px;
    right: 25px;
}
