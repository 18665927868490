.cta-options-block {
    margin-top: 50px;
    margin-bottom: 50px;
}

.cta-options-block__inner {
    @include wrap();
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cta-options-block__option {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-basis: 30%;
    background-color: $color--panel-dark;
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none !important;
    min-height: 200px;
    transition: all 0.2s ease;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
        transform: scale(1.03);
    }

}

.cta-options-block__option-heading {
    font-size: 24px;
    color: $color--heading-light;
    font-weight: $font-weight--medium;
    text-align: center;
    margin-bottom: 40px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        font-size: 22px;
        display: block;
        color: $color--heading-light;
        text-decoration: none !important;
    }
}

.cta-options-block__option-icon {
    max-width: 110px;
    max-height: 70px;
    display: block;
    margin: auto;
}


@media screen and (max-width: 768px) {

    .cta-options-block__option {
        padding-top: 30px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
        min-height: 150px;
        border-radius: 23px;
        flex-basis: 31%;
    }

    .cta-options-block__option-icon {
        max-width: 60px;
        max-height: 40px;
    }

    .cta-options-block__option-heading {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .cta-options-block__option-heading a {
        font-size: 19px;
    }

}


@media screen and (max-width: 580px) {

    .cta-options-block__inner {
        display: block;
    }

    .cta-options-block__option {
        max-width: 230px;
        display: block;
        margin: auto;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

    }

}
