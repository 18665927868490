.sub-page-list {
    padding-bottom: 30px;

    p {
        margin: 0px;
    }
}

.sub-page-list__inner {
    display: flex;
    flex-wrap: wrap;

    .service-card {
        margin: 0px;
        margin-bottom: 16px;
        min-height: 220px;
        flex-basis: calc(33.33333% - 10px);

        &:not(:nth-child(3n)) {
            margin-right: 15px;
        }
    }

    .service-card__inner {
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .service-card__heading {
        font-size: 16px !important;
        margin-bottom: 15px !important;
        min-height: 0px;
    }

    .service-card__cta {
        font-size: 14px !important;
        padding: 9px 17px !important;
    }

}

.sub-page-list__link {

}

.sub-page-list__no-results {}



@media screen and (max-width: 650px) {

    .sub-page-list__inner {

        .service-card {
            flex-basis: calc(50% - 16px);

            &:not(:nth-child(3n)) {
                margin-right: 0px;
            }

            &:not(:nth-child(even)) {
                margin-right: 16px;
            }
        }
    }

}


@media screen and (max-width: 470px) {

    .sub-page-list__inner {

        .service-card {
            flex-basis: 100%;

            &:not(:nth-child(even)) {
                margin-right: 0px;
            }
        }
    }

}
