body.template--services-index {

    .services {}

    .services__inner {
        @include wrap();
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 50px;

        .service-card {
            flex-basis: calc(25% - 22.5px);
            margin-bottom: 30px;

            &:not(:nth-child(4n)) {
                margin-right: 30px;
            }

        }

    }

    @media screen and (max-width: 1150px) {

        .services__inner {

            .service-card {
                flex-basis: calc(33.33333333% - 13.4px);
                margin-bottom: 20px;

                &:not(:nth-child(4n)) {
                    margin-right: 0px;
                }

                &:not(:nth-child(3n)) {
                    margin-right: 20px;
                }

            }

        }

    }

    @media screen and (max-width: 800px) {

        .services__inner {

            .service-card {
                flex-basis: calc(50% - 10px);

                &:not(:nth-child(3n)) {
                    margin-right: 0px;
                }

                &:not(:nth-child(2n)) {
                    margin-right: 20px;
                }

            }

        }

    }


    @media screen and (max-width: 530px) {

        .services__inner {

            .service-card {
                flex-basis: 100%;

                &:not(:nth-child(2n)) {
                    margin-right: 0px;
                }

            }

        }

    }

}
