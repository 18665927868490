.editor-content-block {}

.editor-content-block__inner {
    @include wrap();
    max-width: 992px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.editor-content {
    line-height: 1.64;
}

.editor-content .btn-corner--filled-brand {
    color: $color--text-light !important;
}

.editor-content p,
.editor-content span,
.editor-content li {
    color: $color--text !important;
    font-size: 17px;
    line-height: 1.64;
    font-weight: $font-weight--regular;
}

.editor-content p {
    margin-bottom: 28px;
}

.editor-content a {
    font-size: inherit;
    color: $color--text !important;
    font-weight: $font-weight--medium;
    display: inline-block;
    position: relative;
    text-decoration: none !important;
}

.editor-content a:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #52BB62;
    bottom: -2px;
    left: 0;
    right: 0;
    transition: bottom 0.2s;
}

.editor-content a:hover:after {
    bottom: -3px;
}

.editor-content strong {
    font-size: inherit;
    font-weight: 600;
}

.editor-content br {
    /* display: none; */
}



.editor-content ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.editor-content ul {
    list-style-type: circle;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.editor-content h1,
.editor-content h2,
.editor-content h3,
.editor-content h4,
.editor-content h5,
.editor-content h6 {
    color: $color--heading-brand;
}

.editor-content h1 {
    font-size: 32px;
    line-height: 1.46;
    font-weight: $font-weight--bold;
    margin-top: 25px;
    margin-bottom: 15px;
}


.editor-content h2 {
    font-size: 28px;
    line-height: 1.46;
    font-weight: $font-weight--bold;
    margin-top: 25px;
    margin-bottom: 15px;
}

.editor-content h3 {
    font-size: 23px;
    line-height: 1.48;
    margin-top: 22px;
    margin-bottom: 12px;
    font-weight: $font-weight--bold;
}


.editor-content h4 {
    font-size: 20px;
    margin-top: 22px;
    margin-bottom: 12px;
    font-weight: $font-weight--bold;
    line-height: 1.3;
}

.editor-content h5 {
    font-size: 18px;
    margin-top: 22px;
    margin-bottom: 12px;
    font-weight: $font-weight--bold;
    line-height: 1.3;
}


.editor-content h6 {
    font-size: 16px;
    margin-top: 22px;
    margin-bottom: 12px;
    font-weight: $font-weight--bold;
    line-height: 1.3;
}


.editor-content .table {
    max-width: 100%;
    background-color: #f5f5f5;
    border-radius: 9px;
    // box-shadow: 0px 20px 50px -40px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.04);
    margin-bottom: 30px;
    overflow-x: auto;
    width: 100%;

    table {
        width: 100%;
        table-layout: fixed;
    }

    td {
        font-size: 16px;
        font-weight: $font-weight--medium;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 5px;
        padding-bottom: 5px;

        h1, h2, h3, h4, h5, h6 {
            font-size: 18px;
            font-weight: $font-weight--bold;
            margin: 0px;
            padding-top: 10px;
            padding-bottom: 0px;
            display: block;
        }

    }

}


.editor-content iframe {
    max-width: 100% !important;
}



@media screen and (max-width: 1074px) {

    .editor-content p,
    .editor-content span,
    .editor-content li {
        font-size: 16px;
    }

    .editor-content p {
        margin-bottom: 25px;
    }

    .editor-content ol {
        margin-top: 18px;
        margin-bottom: 28px;
    }

    .editor-content ul {
        margin-top: 18px;
        margin-bottom: 28px;
    }


    .editor-content h1 {
        font-size: 29px;
        margin-top: 24px;
        margin-bottom: 14px;
    }


    .editor-content h2 {
        font-size: 26px;
        margin-top: 23px;
        margin-bottom: 13px;
    }

    .editor-content h3 {
        font-size: 22px;
        margin-top: 21px;
        margin-bottom: 11px;
    }


    .editor-content h4 {
        font-size: 19px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .editor-content h5 {
        font-size: 17px;
        margin-top: 20px;
        margin-bottom: 10px;
    }


    .editor-content h6 {
        margin-top: 20px;
        margin-bottom: 10px;
    }

}


@media screen and (max-width: 768px) {

    .editor-content .table {

        td {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 2px;
            padding-bottom: 2px;

            h1, h2, h3, h4, h5, h6 {
                font-size: 16px;
                padding-top: 5px;
            }

        }

    }

}


@media screen and (max-width: 550px) {

    .editor-content p,
    .editor-content span,
    .editor-content li {
        font-size: 15px;
    }

    .editor-content p {
        margin-bottom: 22px;
    }

    .editor-content ol {
        margin-top: 15px;
        margin-bottom: 24px;
    }

    .editor-content ul {
        margin-top: 15px;
        margin-bottom: 24px;
    }


    .editor-content h1 {
        font-size: 25px;
    }


    .editor-content h2 {
        font-size: 23px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .editor-content h3 {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .editor-content h4 {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .editor-content h5 {
        font-size: 17px;
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .editor-content h6 {
        margin-top: 10px;
        margin-bottom: 10px;
    }


}

@media screen and (max-width: 390px) {

    .editor-content p,
    .editor-content span,
    .editor-content li {
        font-size: 15px;
    }

    .editor-content p {
        margin-bottom: 20px;
    }

    .editor-content ol {
        margin-top: 14px;
        margin-bottom: 22px;
    }

    .editor-content ul {
        margin-top: 14px;
        margin-bottom: 22px;
    }


    .editor-content h1 {
        font-size: 23px;
    }


    .editor-content h2 {
        font-size: 21px;
    }

    .editor-content h3 {
        font-size: 20px;
    }


    .editor-content h4 {
        font-size: 18px;
    }

    .editor-content h5 {
        font-size: 16px;
        margin-top: 10px;
        margin-bottom: 10px;
    }


    .editor-content h6 {
        font-size: 15px;
    }

}
